.checkbox-form {
	$that: &;

	position: relative;
	display: inline-flex;
	justify-content: flex-start;
	align-items: flex-start;
	min-height: 28px;
	padding-left: 43px;
	user-select: none;
	cursor: pointer;

	&._small {
		min-height: 17px;
		padding-left: 35px;
	}

	&:hover {
		#{$that}__box {
			border-color: $color_primary;
			background: rgba($color_primary, 0.06);
		}
	}

	&__input {
		@include visually-hidden();
	}

	&__box {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 28px;
		height: 28px;
		margin-right: 15px;
		// border: 1px solid $color_body-300;
		border-radius: 4px;
		transition: border-color 0.6s cubic-bezier(0.35, 1, 0.5, 1),
			background-color 0.6s cubic-bezier(0.35, 1, 0.5, 1);

		#{$that}__input:checked ~ & {
			border-color: $color_primary;
			background-color: $color_primary;
		}

		._small & {
			top: 2px;
			width: 17px;
			height: 17px;
			margin-right: 18px;
		}

		._light & {
			border-color: $color_body;
		}
	}
	&__mark {
		width: 15px;
		height: 15px;
		color: $white;
		opacity: 0;

		stroke-dasharray: 320;
		stroke-dashoffset: 320;

		#{$that}__input:checked ~ #{$that}__box & {
			opacity: 1;
			stroke-dashoffset: 0;
			transition: opacity 0.6s cubic-bezier(0.35, 1, 0.5, 1),
				stroke-dashoffset 9s cubic-bezier(0.35, 1, 0.5, 1) 0.1s;
		}

		._small & {
			width: 9px;
			height: 9px;
		}

		@keyframes checkboxMarkAnimation {
			from {
				stroke-dashoffset: 320;
			}
			to {
				stroke-dashoffset: 0;
			}
		}
	}

	&__text {
		color: $color_body;
		font-weight: normal;
		font-size: 14px;
		line-height: 22px;
		margin-top: 3px;

		._small & {
			margin-top: 0;
		}

		a {
			border-bottom: 1px solid;
		}
	}
}
