.tags {
	$that: &;

	font-size: 11px;
	line-height: 15px;
	text-transform: uppercase;
	letter-spacing: 0.6px;
	display: flex;
	padding-top: 16px;
	//max-height: 70px;

	@include phone {
		font-size: 10px;
		line-height: 12px;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
	}

	&__item {
		font-weight: 600;
		color: rgba(34, 40, 45, 0.5);
		padding-bottom: 7px;
		padding-top: 12px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		margin-right: 14px;
		white-space: nowrap;

		@include phone {
			&:nth-child(2),
			&:nth-child(3) {
				display: none;
			}
		}
	}

	&__other {
		position: relative;

		@include phone {
			display: none;
		}

		&:hover {
			#{$that}__toggle {
				background: $color_primary;

				svg {
					fill: $white;
				}
			}
			#{$that}__popover {
				opacity: 1;
				pointer-events: all;
			}
		}
	}

	&__toggle {
		width: 24px;
		height: 24px;
		background: #f9f9f9;
		border-radius: 100%;
		@include flex-center();

		svg {
			fill: #7a8084;
		}
	}

	&__popover {
		position: absolute;
		right: 0;
		top: 100%;
		z-index: 20;
		background: #ffffff;
		box-shadow: 10px 10px 79px rgba(155, 163, 172, 0.11);
		border-radius: 4px;
		padding: 14px 26px 26px;
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.2s ease-out;

		.tags__item {
			font-weight: 500;
			color: #7a8084;
			margin-right: 0;
			margin-top: 12px;
			white-space: nowrap;
		}
	}
}
