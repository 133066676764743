// Container placeholder
%container {
	width: 100%;
	max-width: 1900px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 36px;
	padding-right: 36px;

	@include tablet {
		padding-left: 30px;
		padding-right: 30px;
	}

	@include phone-s {
		padding-left: 16px;
		padding-right: 16px;
	}
}
