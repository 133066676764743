.hevel-page {
	$that: &;

	background: #f9f9f9;

	&__container {
		@extend %container;
		position: relative;
	}

	&__body {
		position: relative;
		z-index: 20;
	}

	&__slider {
		background: $white;
		position: relative;
		z-index: 20;
		padding-top: 154px;
		padding-bottom: 436px;

		@include tablet {
			padding-top: 102px;
			padding-bottom: 355px;
		}

		@include phone {
			padding-top: 80px;
			padding-bottom: 170px;
		}
	}

	&__hevel {
		margin-top: -230px;
		padding-bottom: 127px;

		@include tablet {
			margin-top: -190px;
			padding-bottom: 167px;
		}
		@include phone {
			margin-top: -106px;
			padding-bottom: 42px;
		}
	}

	&__hevel-pictures {
		position: relative;
		width: 505px;
		margin: 0 auto;

		@include tablet {
			width: 415px;
			height: 382px;
		}

		@include phone {
			width: 229px;
			height: auto;
		}
	}

	&__hevel-sphere {
		position: absolute;
		top: 35px;
		left: -55px;

		@include phone {
			width: 33px;
			top: -6px;
			left: -3px;
		}
	}

	&__hevel-brand {
		width: 100%;
		@include flex-center();
		color: $white;

		svg {
			width: 146px;
			height: auto;
			position: absolute;

			@include tablet {
				width: 128px;
				//width: 172px;
				//height: 35px;
			}
			@include phone {
				width: 64px;
				//width: 95px;
				//height: 20px;
			}
		}
	}

	&__hevel-atom {
		position: absolute;
		right: -175px;
		bottom: -90px;

		@include phone {
			position: static;
			display: flex;
			justify-content: center;
		}

		svg {
			width: 218px;
			height: 218px;

			@include phone {
				width: 125px;
				height: 125px;
			}
		}
	}

	&__hevel-text {
		max-width: 974px;
		margin: 0 auto;
		font-weight: 500;
		font-size: 18px;
		line-height: 38px;
		text-align: right;
		padding-top: 47px;

		@include tablet {
			padding-top: 112px;
		}

		@include phone {
			font-size: 14px;
			line-height: 28px;
			text-align: center;
			padding-top: 12px;
			max-width: none;
		}

		p {
			max-width: 487px;

			@include phone {
				max-width: none;
			}
		}
	}

	&__picture {
		height: 769px;

		@include laptop {
			height: 570px;
		}

		@include tablet {
			height: auto;
		}

		img {
			width: 100%;
			height: 100%;

			@include object-fit(cover, center);
			@include lazy-loaded;
		}
	}

	&__chart {
		padding-top: 138px;

		@include phone {
			padding-top: 35px;
		}

		&-head {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: start;
			margin-bottom: 200px;

			@include laptop {
				margin-bottom: 136px;
			}

			@include phone {
				margin-bottom: 78px;
			}
		}

		&-title {
			position: relative;
			margin-bottom: 29px;
			font-weight: 500;
			font-size: 48px;
			line-height: 59px;
			text-transform: uppercase;
			text-align: center;

			@include phone {
				font-size: 24px;
				line-height: 29px;
				margin-bottom: 22px;
			}

			span {
				border-bottom: 2px solid rgba(0, 0, 0, 0.15);
			}
		}

		&-subtitle {
			position: relative;
		}

		&-year {
			font-style: normal;
			font-weight: normal;
			font-size: 57px;
			line-height: 67px;
			color: #404142;
			text-align: center;

			@include phone {
				font-size: 32px;
				line-height: 37px;
			}

			span {
				font-family: Montserrat;
				font-style: normal;
				font-weight: 500;
				font-size: 22px;
				line-height: 26px;
				color: #484848;

				@include phone {
					font-size: 20px;
					line-height: 24px;
				}
			}
		}

		&-icon {
			position: absolute;
			top: 65%;
			left: 50%;
			transform: translateX(-50%);
			display: flex;
			align-items: center;
			justify-content: center;
			width: 174px;
			height: 174px;
			border: 1px solid #d4d4d4;
			border-radius: 50%;
			z-index: -1;

			@include phone {
				width: 96px;
				height: 96px;
			}

			img {
				display: block;

				@include phone {
					width: 30px;
				}
			}
		}
	}

	&__assets {
		padding: 127px 0 130px;

		@include tablet {
			padding: 112px 0;
		}
		@include phone {
			padding: 66px 0 80px;
		}
	}

	&__assets-head {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__assets-title {
		padding-bottom: 8px;
		font-weight: 500;
		font-size: 48px;
		line-height: 59px;
		text-align: center;
		text-transform: uppercase;
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);

		@include phone {
			font-size: 24px;
			line-height: 40px;
		}
	}

	&__assets-container {
		margin: 0 auto;
	}

	&__assets-body {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		padding-top: 141px;
		margin-left: -46px;

		@include laptop {
			flex-wrap: wrap;
			justify-content: flex-start;
			padding-top: 48px;
		}

		@include tablet {
			margin-left: -30px;
		}
		@include phone {
			display: block;
			margin-top: -48px;
			margin-left: 0;
		}
	}

	&__assets-item {
		position: relative;
		flex: 1 1 0%;
		margin-left: 46px;
		padding-bottom: 138px;
		display: block;

		@include laptop {
			flex: 1 1 calc(50% - 46px);
			max-width: calc(50% - 46px);
			margin-top: 96px;
		}

		@include tablet {
			margin-left: 30px;
			padding-bottom: 112px;
		}

		@include phone {
			flex: 1 1 100%;
			max-width: none;
			margin-left: 0;
			margin-top: 48px;
			padding-top: 48px;
			padding-bottom: 92px;
		}
	}

	&__assets-label {
		padding: 17px 26px 14px;
		background: $color_primary;
		position: absolute;
		bottom: 100%;
		left: 50%;
		transform: translateX(-50%);
		@include flex-center();
		margin-left: 23px;

		@include tablet {
			margin-left: 15px;
		}

		@include phone {
			margin-left: 0;
			bottom: calc(100% - 48px);
		}

		svg {
			width: 105px;
			height: 29px;
			color: $white;
		}
	}

	&__assets-picture {
		height: 295px;
		background: $color_primary;
		@include flex-center();

		@include phone {
			height: 198px;
		}

		img {
			width: 100%;
			height: 100%;

			@include object-fit(cover, center);
			@include lazy-loaded;
		}

		svg {
			width: 125px;
			height: 94px;

			@include phone {
				width: 91px;
				height: 68px;
			}
		}
	}

	&__assets-name {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		background: $white;
		padding: 47px 30px;
		border-radius: 0 0 4px 4px;
		@include flex-center();
		flex-direction: column;

		@include tablet {
			padding: 34px 30px;
		}
		@include phone {
			padding: 24px 16px;
		}
	}

	&__assets-link {
		font-weight: 500;
		font-size: 24px;
		line-height: 36px;
		text-transform: uppercase;
		color: #22282d;

		display: flex;
		justify-content: flex-start;
		align-items: center;

		@include phone {
			font-size: 18px;
		}

		span {
			padding-right: 18px;

			@include tablet {
				padding-right: 10px;
			}
		}
	}

	&__assets-desc {
		position: absolute;
		margin-top: 20px;
		padding-top: 12px;
		font-size: 18px;
		font-weight: 500;
		line-height: 2.111111111;
		opacity: 0;
		pointer-events: none;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			width: 38px;
			margin-left: -18px;
			border-bottom: 1px solid #45983a;
		}

		#{$that}__assets-item:hover & {
			position: relative;
			opacity: 1;
			pointer-events: all;
			transition: opacity 0.35s ease-in;
		}

		@include tablet {
			font-size: 14px;
		}
	}
}
