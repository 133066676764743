.header {
	$that: &;

	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	transition: border-color 0.25s ease, box-shadow 0.25s ease, color 0.25s ease;
	min-height: 63px;

	@include phone {
		min-height: 70px;
	}

	&__container {
		@extend %container;
	}

	&__columns {
		display: flex;
		justify-content: space-between;
		margin-left: -23px;
		margin-right: -23px;

		@include laptop {
			margin: 0;
		}

		@include phone {
			// margin-left: -12px;
			// margin-right: -12px;
		}
	}

	&__column {
		flex: 1 1 0%;
		padding-left: 23px;
		padding-right: 23px;

		@include laptop {
			padding: 0;
		}

		@include desktop {
			// flex: 1 1 auto;
		}

		@include phone {
			// padding-left: 12px;
			// padding-right: 12px;
		}
	}

	&__logo {
		padding-top: 34px;
		padding-bottom: 32px;
		transition: padding-top 0.25s cubic-bezier(0, 0.55, 0.45, 1),
			padding-bottom 0.25s cubic-bezier(0, 0.55, 0.45, 1);
		will-change: padding-top, padding-bottom;

		@include laptop {
			flex: none;
		}

		@include tablet {
			padding-top: 36px;
			padding-bottom: 26px;
		}

		@include phone {
			padding-top: 24px;
			padding-bottom: 25px;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&__link-logo {
		display: inline-block;
		vertical-align: top;
		color: $white;

		&:hover {
			color: $white;
		}
	}

	&__svg-logo {
		transition: transform 0.25s cubic-bezier(0, 0.55, 0.45, 1),
			fill 0.4s ease-in-out;
		transform-origin: left center;
		will-change: transform;
		fill: $white;

		@include phone {
			width: 114px;
			height: 38px;
		}
	}

	&__nav {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 34px;
		padding-bottom: 32px;
		flex: none;

		@include laptop {
			display: none;
		}
	}

	&__panel {
		display: flex;
		justify-content: flex-end;

		@include phone {
			flex: none;
			width: 100%;
			padding: 36px 0;
			transition: padding-top 0.25s cubic-bezier(0, 0.55, 0.45, 1),
				padding-bottom 0.25s cubic-bezier(0, 0.55, 0.45, 1);
			will-change: padding-top, padding-bottom;
		}
	}

	&__lng {
		display: flex;
		align-items: center;
		padding-left: 48px;
		padding-right: 20px;
		//border-left: 1px solid rgba(255, 255, 255, 0.1);
		transition: border-color 0.25s ease;

		@include laptop {
			border: none;
			padding-left: 43px;
			padding-right: 0;
		}

		@include phone {
			padding-left: 0;
		}
	}

	&__lng-body {
		position: relative;
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		text-transform: uppercase;
	}

	&__lng-toggle {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		cursor: pointer;
		color: $white;

		span {
			padding-left: 6px;
		}
		svg {
			color: rgba($white, 0.48);
			transform: rotateZ(0deg);
			transition: transform 0.6s cubic-bezier(0.1, 0, 0.14, 1.5);

			._is-lng-active & {
				transform: rotateZ(180deg);
			}
		}
	}

	&__lng-list {
		position: absolute;
		top: 100%;
		left: -10px;
		padding: 11px 32px;
		border-radius: 2px;
		background: $white;
		margin-top: 15px;
		box-shadow: 0px 5px 38px rgba(121, 128, 134, 0.11);

		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		transform: translateY(-6px);
		transition: opacity 0.6s cubic-bezier(0.1, 0, 0.14, 1.5),
			visibility 0.6s cubic-bezier(0.1, 0, 0.14, 1.5),
			transform 0.6s cubic-bezier(0.1, 0, 0.14, 1.5);

		._is-lng-active & {
			opacity: 1;
			visibility: visible;
			pointer-events: all;
			transform: translateY(0px);
		}

		svg {
			position: absolute;
			top: -6px;
			left: 3px;
		}
	}

	&__lng-item {
		color: $color_body;
	}

	&__burger {
		display: none;
		align-items: center;
		justify-content: flex-end;
		flex-grow: 1;

		@include laptop {
			display: flex;
		}

		@include phone {
			border: none;
			margin-left: 24px;
			padding-left: 0;
		}

		@include phone-s {
			margin-left: 16px;
		}

		svg {
			fill: $white;
		}
	}

	&._secondary {
		background: $white;
		border-color: rgba($black, 0.04);

		.header__svg-logo {
			fill: $black;

			path._path-green {
				fill: $color_primary;
			}
		}

		.header-nav__link {
			color: rgba($black, 0.8);
		}

		.header-nav__link:hover,
		.header-nav__item._is-active .header-nav__link,
		.header-nav__item._popover:hover .header-nav__link {
			color: $color_primary;
		}

		.header__burger {
			svg {
				fill: $color_body;
			}
		}

		.header__lng {
			border-color: rgba($black, 0.04);
		}

		.header__lng-toggle {
			color: $black;

			svg {
				color: rgba($black, 0.38);
			}
		}
	}

	&._header-transparent {
		.header__svg-logo {
			fill: $black;

			path._path-green {
				fill: $color_primary;
			}
		}

		.header-nav__link {
			color: rgba($black, 0.8);
		}

		.header-nav__link:hover,
		.header-nav__item._is-active .header-nav__link,
		.header-nav__item._popover:hover .header-nav__link {
			color: $color_primary;
		}

		.header__burger {
			svg {
				fill: $color_body;
			}
		}

		.header__lng {
			border-color: rgba($black, 0.04);
		}

		.header__lng-toggle {
			color: $black;

			svg {
				color: rgba($black, 0.38);
			}
		}
	}

	.page_scrolled & {
		background: $white;
		box-shadow: 10px 10px 85px rgba(155, 163, 172, 0.15);
		border-color: rgba($black, 0.04);

		.header__logo {
			padding-top: 12px;
			padding-bottom: 12px;

			@include laptop {
				padding-top: 16px;
				padding-bottom: 16px;
			}
		}

		.header__svg-logo {
			fill: $black;
			transform: scale(0.7);

			@include laptop {
				transform: none;
				will-change: auto;
			}

			path._path-green {
				fill: $color_primary;
			}
		}

		.header__nav {
			padding-top: 12px;
			padding-bottom: 12px;
		}
		.header-nav__link {
			color: rgba($black, 0.8);
		}

		.header-nav__link:hover,
		.header-nav__item._is-active .header-nav__link,
		.header-nav__item._popover:hover .header-nav__link {
			color: $color_primary;
		}

		.header__panel {
			@include phone {
				padding: 28px 0;
			}
		}

		.header__burger {
			svg {
				fill: $color_body;
			}
		}

		.header__lng {
			border-color: rgba($black, 0.04);
		}

		.header__lng-toggle {
			color: $black;

			svg {
				color: rgba($black, 0.38);
			}
		}
	}
}
