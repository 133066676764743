.news-item {
	$that: &;

	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	transition: box-shadow 1s cubic-bezier(0.25, 0.1, 0, 1),
		color 1s cubic-bezier(0.25, 0.1, 0, 1);
	position: relative;
	background: $white;
	will-change: transform;

	&::after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 0%;
		height: 4px;
		background: $color_primary;
		transition: width 1s cubic-bezier(0.25, 0.1, 0, 1);
	}

	&:hover {
		@include laptop-hover {
			box-shadow: 24px 24px 54px rgba(121, 128, 134, 0.08);

			&::after {
				width: 100%;
			}

			#{$that}__picture {
				img {
					transform: scale(1.1);
				}
			}
			#{$that}__date {
				color: $color_body;
			}
		}
	}

	&__picture {
		height: 281px;
		flex: none;
		border-radius: 4px 4px 0 0;
		overflow: hidden;

		@include phone {
			height: 155px;
		}

		img {
			width: 100%;
			height: 100%;

			@include object-fit(cover, center);
			@include lazy-loaded;

			transform: scale(1);
			transition: transform 2s cubic-bezier(0.25, 0.1, 0, 1);
			will-change: transform;
		}

		& + #{$that}__body {
			border-radius: 0 0 4px 4px;
		}
	}

	&__body {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		padding: 36px 46px 38px;
		border: 1px solid #e5e5e5;
		border-radius: 4px;
		//flex-grow: 1;

		@include phone {
			padding: 19px 26px 26px;
		}
	}

	&__title {
		font-weight: 600;
		font-size: 18px;
		line-height: 2;
		text-transform: uppercase;
		height: 108px;
		flex: none;

		@include phone {
			font-size: 12px;
			height: 80px;
		}
	}

	&__date {
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #7a8084;
		padding-top: 20px;
		margin-top: auto;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		transition: color 1s cubic-bezier(0.25, 0.1, 0, 1);

		@include phone {
			padding-top: 11px;
			font-size: 10px;
			line-height: 12px;
		}

		svg {
			width: 24px;
			height: 24px;
			color: #22282d;

			@include phone {
				width: 20px;
				height: 20px;
			}
		}

		._date {
			padding-left: 11px;

			@include phone {
				padding-left: 7px;
			}
		}
	}

	&__desc {
		font-weight: 500;
		font-size: 16px;
		line-height: 30px;
		color: #606367;
		padding-top: 23px;
		max-height: 263px;
		overflow: hidden;
		position: relative;

		@include phone {
			font-size: 12px;
			line-height: 16px;
			padding-top: 11px;
			max-height: 108px;
		}

		&::after {
			content: '';
			position: absolute;
			right: 0;
			bottom: 0;
			width: 100px;
			height: 30px;
			background: linear-gradient(
				90deg,
				rgba($white, 0) 0%,
				rgba($white, 1) 50%
			);

			@include phone {
				height: 16px;
			}
		}
	}

	&__tags {
		flex: none;
		display: flex;
		align-items: flex-end;
	}
}
