.management-swiper {
	$that: &;
	position: relative;

	&__swiper-container.swiper-container {
		overflow: visible;
	}

	&__swiper-wrapper.swiper-wrapper {
		@media (min-width: 1536px) {
			justify-content: space-between;
		}
	}

	&__swiper-slide.swiper-slide {
		max-width: 422px;

		@media (min-width: 1536px) {
			width: 19%;
		}

		@include tablet {
			max-width: 382px;
		}
		@include phone {
			max-width: 274px;
		}
	}

	&__item {
		cursor: pointer;

		&:hover {
			@include laptop-hover {
				#{$that}__picture {
					img {
						transform: scale(1.1);
					}
				}
			}
		}

		@include phone {
		}
	}

	&__picture {
		height: 552px;
		overflow: hidden;

		@include phone {
			height: 396px;
		}

		img {
			width: 100%;
			height: 100%;

			@include object-fit(cover, center);
			@include lazy-loaded;

			transform: scale(1);
			transition: transform 2s cubic-bezier(0.25, 0.1, 0, 1);
		}
	}

	&__main {
		display: flex;
		flex-direction: column;
		min-height: 240px;

		@include phone {
			min-height: 215px;
		}
	}

	&__desc {
		padding-top: 28px;

		@include phone {
			padding-top: 24px;
		}
	}

	&__name {
		font-weight: 500;
		font-size: 24px;
		line-height: 41px;
		text-transform: uppercase;
		padding-bottom: 4px;

		@include phone {
			font-size: 16px;
			line-height: 20px;
			padding-bottom: 13px;
		}
	}

	&__position {
		font-weight: 600;
		font-size: 16px;
		line-height: 26px;
		color: $color_primary;
		padding-bottom: 14px;
		padding-right: 10px;
	}

	&__arrow {
		margin-top: auto;
	}
}
