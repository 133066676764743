// Path image
$path_image: '../images/';

// Colors
$black: #000;
$white: #fff;

$color_primary: #45983a;
$color_body: #151617;

$color_baikal: #3E639A;

$red: #c16363;

// Fonts
$font_body: 'Montserrat', -apple-system, system-ui, BlinkMacSystemFont,
	'Segoe UI', 'Roboto', 'Helvetica Neue', Arial, sans-serif,
	'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font_display: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
	'Roboto', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
	'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
