.ecotourism-page {
	$that: &;

	&__wrap {
		position: relative;
		z-index: 20;
		background: $white;
	}

	&__container {
		@extend %container;
		position: relative;
	}

	&__body {
		position: relative;
		z-index: 20;
	}

	&__desc {
		padding: 154px 0 243px;
		position: relative;

		@include tablet {
			padding: 92px 0 165px;
		}
		@include phone {
			padding: 77px 0;
		}
	}

	&__desc-title {
		font-weight: 500;
		font-size: 48px;
		line-height: 72px;
		text-align: center;
		text-transform: uppercase;
		padding-bottom: 79px;

		@include tablet {
			padding-bottom: 107px;
		}
		@include phone {
			font-size: 24px;
			line-height: 29px;
			padding-bottom: 40px;
		}

		span {
			border-bottom: 1px solid;
		}
	}

	&__desc-body {
		position: relative;
		max-width: 1200px;
		margin: 0 auto;
		display: flex;
		align-items: center;

		@include tablet {
			display: block;
			max-width: 790px;
		}
	}

	&__desc-picture {
		width: 604px;
		height: 704px;
		flex: none;
		overflow: hidden;

		@include tablet {
			width: 100%;
			height: auto;
		}

		img {
			width: 100%;
			height: 100%;

			@include object-fit(cover, center);
			@include lazy-loaded;
		}
	}

	&__desc-text {
		padding-left: 131px;
		font-weight: 500;
		font-size: 18px;
		line-height: 38px;
		display: flex;
		flex-direction: column;

		@include laptop {
			padding-left: 32px;
		}

		@include tablet {
			padding-left: 0;
			text-align: center;
			font-size: 14px;
			line-height: 28px;
			padding-top: 10px;
		}

		p {
			padding-bottom: 53px;

			@include laptop {
				padding-bottom: 25px;
			}

			@include tablet {
				padding-top: 20px;
				padding-bottom: 0;
			}
		}
	}

	&__mountain {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 204px;
		height: 204px;
		margin: auto 0 0;
		@include flex-center();
		margin-left: auto;
		transform: translate3d(-70%, 70%, 0);

		@include tablet {
			display: none;
		}
	}

	&__mountain-icon {
		position: absolute;

		svg {
			color: $color_primary;
			width: 79px;
			height: 79px;
		}
	}

	&__mountain-text {
		position: absolute;

		svg {
			animation: textCircleRotateAnimation 28s linear;
			width: 100%;
			height: 204px;
			color: rgba($color_body, 0.4);
		}
	}

	&__picture {
		height: 769px;

		@include laptop {
			height: 554px;
		}

		@include tablet {
			height: auto;
		}

		img {
			width: 100%;
			height: 100%;

			@include object-fit(cover, center);
			@include lazy-loaded;
		}
	}

	&__laguna {
		padding-bottom: 116px;
		text-align: center;
		position: relative;

		@include tablet {
			padding-bottom: 56px;
		}
		@include phone {
			padding-bottom: 52px;
		}
	}

	&__laguna-title {
		font-weight: 500;
		font-size: 48px;
		line-height: 59px;
		text-align: center;
		text-transform: uppercase;
		border-bottom: 2px solid $color_primary;
		padding-bottom: 2px;
		display: inline-block;

		@include phone {
			font-size: 42px;
			line-height: 51px;
		}

		span {
			color: $color_primary;
		}
	}

	&__laguna-back {
		position: absolute;
		top: -110px;
		left: 50%;
		transform: translateX(-50%);

		font-weight: normal;
		font-size: 184px;
		line-height: 255px;
		text-transform: uppercase;
		color: rgba($black, 0.04);

		@include tablet {
			font-size: 18vw;
		}
		@include phone {
			font-size: 22vw;
			line-height: 115px;
			top: -60px;
		}
	}

	&__laguna-body {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		padding-top: 68px;
		max-width: 1286px;
		margin: 0 auto;

		@include tablet {
			padding-top: 86px;
		}
		@include phone {
			padding-top: 11px;
			display: block;
		}
	}

	&__laguna-text {
		padding: 0 40px;
		font-weight: 500;
		font-size: 18px;
		line-height: 38px;
		width: 50%;

		p + p {
			margin-top: 24px;
		}

		&:first-child {
			font-weight: 500;
			font-size: 24px;
			line-height: 41px;
			text-transform: uppercase;

			@include tablet {
				font-size: 18px;
				text-transform: none;
				line-height: 38px;
			}

			@include phone {
				padding: 20px 0 0;
				text-align: center;
				font-size: 14px;
				line-height: 26px;
				width: 100%;
			}
		}

		@include tablet {
			padding: 0 32px;
		}

		@include phone {
			padding: 20px 0 0;
			text-align: center;
			font-size: 14px;
			line-height: 26px;
			width: 100%;
		}

		@media (min-width: 640px) {
			&:first-child {
				text-align: right;
			}
			&:last-child {
				text-align: left;
			}
		}
	}

	&__laguna-link {
		margin-top: 8px;
		font-size: 18px;
		text-transform: initial;

		@include phone {
			font-size: 14px;
		}
	}

	&__chart {
		padding-top: 178px;
		padding-bottom: 130px;
		background-color: #f9f9f9;

		@include tablet {
			padding-top: 150px;
			padding-bottom: 120px;
		}
		@include phone {
			padding-top: 50px;
			padding-bottom: 50px;
		}

		&-title {
			margin-bottom: 29px;
			font-weight: 500;
			font-size: 48px;
			line-height: 59px;
			text-transform: uppercase;
			text-align: center;

			@include phone {
				font-size: 24px;
				line-height: 29px;
				margin-bottom: 22px;
			}

			span {
				border-bottom: 2px solid rgba(0, 0, 0, 0.15);
			}
		}

		&-wrapper {
			width: 100%;
			max-width: 1510px;
			margin: 0 auto;
			margin-top: 88px;
			padding: 52px 72px 53px 75px;
			background-color: $white;
			border: 1px solid rgba(0, 0, 0, 0.1);
			border-radius: 2px;
			overflow-x: scroll;
			overflow-y: hidden;

			&::-webkit-scrollbar {
				height: 4px; //для горизонтального скролла
			}
			&::-webkit-scrollbar-track {
				background: transparent;
				border-radius: 10px;
			}
			&::-webkit-scrollbar-thumb {
				bottom: 5px;
				background-color: $color_primary;
				border: none;
				border-radius: 10px;
			}

			@include tablet {
				padding-right: 20px;
				padding-left: 20px;
			}

			@include phone {
				margin-top: 45px;
				padding-top: 26px;
				padding-right: 0;
				padding-left: 17px;
				padding-top: 42px;
			}
		}
	}
}
