.news-swiper {
	$that: &;

	&__swiper-container.swiper-container {
		overflow: visible;
	}

	&__swiper-wrapper.swiper-wrapper {
	}

	&__swiper-slide.swiper-slide {
		@include desktop {
			max-width: 466px;
		}
		@include phone {
			max-width: 257px;
		}
	}

	&__item {
		height: 554px;

		@include phone {
			height: 326px;
		}

		.news-item__title {
			@include phone {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}
}
