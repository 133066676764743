.about-page {
	$that: &;

	overflow: hidden;
	padding-bottom: 188px;
	position: relative;

	@include tablet {
		padding-bottom: 120px;
	}
	@include phone {
		padding-bottom: 70px;
	}

	&__hero {
		position: relative;
		padding-top: 114px;
		padding-bottom: 92px;
		background: #f9f9f9;

		@include laptop {
			height: 918px;
		}

		@include tablet {
			padding-top: 110px;
			padding-bottom: 110px;
		}
		@include phone {
			padding-top: 87px;
			padding-bottom: 87px;
			height: 750px;
		}
	}

	&__breadcrumbs {
		@extend %container;
		margin-top: 23px;

		@include laptop {
			position: relative;
			z-index: 20;

			.breadcrumbs__item {
				svg {
					color: $white;
				}

				span {
					color: #8b939a;
				}
			}
			.breadcrumbs__link {
				color: $white;
			}
		}

		@include phone {
			margin-top: 14px;
		}
	}

	&__hero-inner {
		@extend %container;

		@include laptop {
			height: 100%;
		}
	}

	&__hero-text {
		padding-top: 216px;
		position: relative;
		z-index: 20;
		text-align: right;

		@include laptop {
			color: $white;
			padding-top: 0;
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: center;
			align-items: center;
		}
	}

	&__title {
		max-width: 585px;
		font-weight: 500;
		font-size: 72px;
		line-height: 76px;
		text-transform: uppercase;

		@include phone {
			font-size: 28px;
			line-height: 40px;
		}
	}

	&__subtitle {
		font-weight: 500;
		font-size: 18px;
		line-height: 34px;
		padding-top: 19px;
		max-width: 585px;

		@include laptop {
			text-align: center;
		}

		@include phone {
			font-size: 12px;
			line-height: 16px;
			padding-top: 12px;
		}
	}

	&__globe {
		width: 167px;
		height: 167px;
		margin: 120px 0 0;
		position: relative;
		@include flex-center();

		@include laptop {
			display: none;
		}
	}

	&__globe-icon {
		box-shadow: 0px 10px 61px rgba(163, 184, 190, 0.25);
		border-radius: 100%;
		position: absolute;

		svg {
			width: 84px;
			height: 84px;

			@include phone {
				width: 62px;
				height: 62px;
			}
		}
	}

	&__globe-text {
		position: absolute;

		svg {
			animation: textCircleRotateAnimation 28s linear;
			width: 100%;
			height: 100%;
			color: rgba($color_body, 0.4);
		}
	}

	&__arrow-down {
		position: absolute;
		top: calc(100% - 60px);
		transform-origin: center;
		transform: rotateZ(36deg);

		@include laptop {
			display: none;
		}
	}

	&__hero-picture {
		position: absolute;
		right: 0;
		top: 190px;
		height: 804px;
		width: 896px;
		overflow: hidden;

		@include laptop {
			top: 0;
			width: 100%;
			height: 100%;

			&::before {
				content: '';
				position: absolute;
				z-index: 1;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(
						180deg,
						rgba(11, 14, 26, 0.48) 0.91%,
						rgba(11, 14, 26, 0) 40.16%
					),
					linear-gradient(
						0deg,
						rgba(11, 14, 26, 0.4),
						rgba(11, 14, 26, 0.4)
					);
				pointer-events: none;
			}
		}

		img {
			width: 100%;
			height: 100%;

			@include object-fit(cover, top left);
			@include lazy-loaded;
		}
	}

	&__container {
		@extend %container;
		position: relative;

		@include laptop {
			background: $white;
		}
	}

	&__body {
		position: relative;
		z-index: 20;
		padding-top: 276px;

		@include laptop {
			padding-top: 94px;
		}

		@include phone {
			padding-top: 40px;
		}
	}

	&__management {
		padding-top: 126px;

		.title-section {
			padding-bottom: 60px;

			@include phone {
				padding-bottom: 40px;
			}
		}

		@include tablet {
			padding-top: 91px;
		}
		@include phone {
			padding-top: 46px;
		}
	}

	&__ream {
		position: relative;
		padding: 277px 0 165px;
		text-align: center;

		@include tablet {
			padding: 235px 0 59px;
		}
		@include phone {
			padding: 87px 0 46px;
		}
	}

	&__ream-back {
		position: absolute;
		bottom: 220px;
		left: 50%;
		transform: translateX(-50%);
		color: rgba($black, 0.02);

		@include tablet {
			bottom: 80px;
			width: 100%;
		}
		@include phone {
			bottom: 30px;
		}
		@media (max-width: 523px) {
			bottom: 80px;
		}
		@include phone-s {
			bottom: 50px;
		}

		svg {
			width: 901px;
			height: 203px;

			@include tablet {
				width: 100%;
			}
		}
	}

	&__ream-title {
		font-weight: 500;
		font-size: 72px;
		line-height: 88px;
		text-transform: uppercase;
		padding-bottom: 5px;

		@include tablet {
			font-size: 52px;
			line-height: 63px;
		}

		@include phone {
			font-size: 42px;
			line-height: 56px;
		}
		@include phone-s {
			font-size: 32px;
			line-height: 44px;
		}

		span {
			border-bottom: 1px solid $color_primary;
		}
	}

	&__picture {
		height: 898px;

		@include laptop {
			height: 553px;
		}

		@include tablet {
			height: auto;
		}

		img {
			width: 100%;
			height: 100%;

			@include object-fit(cover, center);
			@include lazy-loaded;
		}
	}

	&__history {
		padding: 288px 0 254px;

		@include tablet {
			padding: 218px 0 181px;
		}
		@include phone {
			padding: 85px 0;
		}
	}

	&__pioneer {
		.title-section__title {
			margin-left: 0;
			font-size: 72px;
			line-height: 88px;

			@include tablet {
				font-size: 52px;
				line-height: 63px;
			}
			@include phone {
				font-size: 42px;
				line-height: 56px;
			}
		}
	}

	&__pioneer-text {
		padding-top: 43px;
		font-weight: 500;
		font-size: 24px;
		line-height: 41px;
		text-align: center;
		text-transform: uppercase;
		max-width: 1024px;
		margin: 0 auto;

		@include phone {
			padding-top: 29px;
			font-size: 20px;
			line-height: 36px;
		}
	}
}
