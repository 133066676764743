.menu-sub {
	$that: &;

	&__head {
		@extend %container;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #f8f8f8;
		padding-top: 18px;
		padding-bottom: 18px;
		color: $color_body;
		position: relative;
	}

	&__back {
		flex: none;
		display: block;
		position: absolute;
		left: 30px;

		@include phone-s {
			left: 16px;
		}
	}

	&__title {
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		text-transform: uppercase;
	}

	&__list {
		padding-top: 10px;
		@extend %container;
	}

	&__item {
		margin-top: 22px;
	}

	&__link {
		color: $color_body;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
	}
}
