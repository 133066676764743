.button {
	$that: &;

	display: inline-flex;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	height: 62px;
	padding: 0px;
	width: 212px;
	color: $color_body;
	font-weight: 600;
	font-size: 12px;
	line-height: 28px;
	text-align: center;
	text-transform: uppercase;
	text-decoration: none;
	border: none;
	border-radius: 4px;
	background: transparent;
	user-select: none;
	appearance: none;
	cursor: pointer;
	position: relative;
	transition: color 0.8s cubic-bezier(0.1, 0, 0.14, 1);

	&._light {
		color: $white;

		&::before,
		&::after {
			background: $white;
		}
	}

	&:hover {
		color: $color_primary;

		&._light {
			color: $white;

			&::before,
			&::after {
				background: $white;
			}

			#{$that}__edge {
				svg {
					stroke: $white;
				}
			}
		}

		&::before,
		&::after {
			background: $color_primary;
		}
		&::before {
			left: 12px;
		}
		&::after {
			right: 12px;
		}

		#{$that}__edge {
			svg {
				stroke: $color_primary;
			}

			path {
				d: path(
					'M192 1H5C2.8 1 1 2.8 1 5V11V51V57C1 59.2 2.8 61 5 61H11'
				);
			}
		}
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		width: 5px;
		height: 1px;
		background: $color_body;
		transition: left 0.8s cubic-bezier(0.1, 0, 0.14, 1) 0.15s,
			right 0.8s cubic-bezier(0.1, 0, 0.14, 1) 0.15s,
			background 0.8s cubic-bezier(0.1, 0, 0.14, 1);
	}

	&::before {
		left: 45px;
	}
	&::after {
		right: 45px;
	}

	&__name {
		display: block;
	}

	&__edge {
		position: absolute;
		top: 0;
		pointer-events: none;

		&._left {
			left: 0;
		}
		&._right {
			right: 0;
			transform: rotateZ(180deg);
		}

		svg {
			stroke: $color_body;
			transition: stroke 0.8s cubic-bezier(0.1, 0, 0.14, 1);

			._light & {
				stroke: rgba($white, 0.44);
			}
		}

		path {
			transition: d 0.8s cubic-bezier(0.1, 0, 0.14, 1);
		}
	}
}
