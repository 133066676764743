.app-head {
	$that: &;

	padding-top: 148px;

	&__subtitle {
		font-weight: normal;
		font-size: 12px;
		line-height: 15px;
		text-align: center;
		letter-spacing: 11.5px;

		@include phone {
			display: none;
		}
	}

	&__title {
		padding-top: 17px;
		padding-bottom: 70px;
		font-weight: 500;
		font-size: 72px;
		line-height: 88px;
		text-align: center;
		text-transform: uppercase;

		@include tablet {
			font-size: 62px;
			line-height: 76px;
			padding-top: 15px;
			padding-bottom: 43px;
		}
		@media (max-width: 767px) {
			font-size: 28px;
			line-height: 34px;
		}
		@include phone {
			padding-top: 38px;
			padding-bottom: 52px;
		}
	}
}
