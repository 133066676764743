.arrow {
	$that: &;

	width: 44px;
	height: 44px;
	cursor: pointer;
	position: relative;
	@include flex-center();

	&::before,
	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		border-radius: 100%;
		transform-origin: center center;
		transform: scale(1);
	}

	&::before {
		border: 1px solid #e5e5e5;
		opacity: 1;
		transition: transform 0.6s cubic-bezier(0.35, 1, 0.5, 1) 0.2s,
			opacity 0.6s cubic-bezier(0.35, 1, 0.5, 1);
	}

	&::after {
		background: $color_primary;
		transform: scale(0);
		transition: transform 0.8s cubic-bezier(0.5, 1, 0.2, 1) 0.1s;
	}

	&._light {
		&::before {
			border-color: rgba($white, 0.2);
		}
		&::after {
			background: $white;
		}

		svg {
			fill: $white;
			stroke: $white;
		}

		&:hover,
		.title-section:hover & {
			@media (min-width: 1025px) {
				svg {
					fill: $color_primary;
					stroke: $color_primary;
				}
			}
		}
	}

	a:hover &._light {
		@media (min-width: 1025px) {
			svg {
				fill: $color_primary;
				stroke: $color_primary;
			}
		}
	}

	&._mid {
		width: 63px;
		height: 63px;

		svg {
			width: 17px;
			height: 14px;
		}
	}

	&._big {
		width: 70px;
		height: 70px;

		&::before {
			border-color: rgba($black, 0.2);
		}

		svg {
			stroke: #cccccc;
			width: 20px;
			height: 16px;
		}

		&:hover,
		.title-section:hover & {
			@media (min-width: 1025px) {
				svg {
					stroke: $white;
				}
			}
		}
	}
	a:hover &._big {
		@media (min-width: 1025px) {
			svg {
				stroke: $white;
			}
		}
	}

	svg {
		width: 14px;
		height: 11px;
		fill: $white;
		stroke: $color_body;
		transition: fill 0.3s ease-out 0.3s, stroke 0.3s ease-out 0.2s;
	}

	&:hover,
	.title-section:hover &,
	a:hover &,
	.management-swiper__item:hover & {
		@media (min-width: 1025px) {
			color: $white;
			transition: color 0.3s ease-out;

			&::before {
				transform: scale(1.2);
				opacity: 0;
				transition: transform 0.6s cubic-bezier(0.35, 1, 0.5, 1),
					opacity 0.6s cubic-bezier(0.35, 1, 0.5, 1) 0.15s;
			}

			&::after {
				transform: scale(1.2);
				transition: transform 0.6s cubic-bezier(0.3, 1, 0.4, 1) 0.15s;
			}

			#{$that}__icon {
				animation: iconArrowNextMove 0.6s ease-out forwards 0.15s;
			}

			svg {
				stroke: $white;
			}
		}
	}

	&__icon {
		z-index: 20;
		margin-left: 3px;

		@keyframes iconArrowNextMove {
			0% {
				transform: translateX(0%);
				opacity: 1;
			}
			40% {
				transform: translateX(100%);
				opacity: 0;
			}
			50% {
				transform: translateX(-100%);
				opacity: 0;
			}
			100% {
				transform: translateX(0%);
				opacity: 1;
			}
		}
	}
}
