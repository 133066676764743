.dots-swiper.swiper-pagination-bullets {
	$that: &;

	&.swiper-pagination-lock {
		display: none;
	}

	.swiper-pagination-bullet {
		width: 30px;
		height: 30px;
		border: 1px dashed #dadada;
		background-color: transparent;
		opacity: 0.8;
		margin-right: 25px;
		position: relative;
		transition: opacity 0.45s, border 0.45s ease;

		&:last-child {
			margin-right: 0;
		}

		&::after {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(0);
			content: '';
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: $color_primary;
			opacity: 0;
			transition: opacity 0.45s ease, transform 0.45s ease;
		}

		&:hover {
			border-color: rgba($color_primary, 0.3);
			animation: bulletsRotate 15s linear infinite;

			&::after {
				opacity: 1;
				transform: translate(-50%, -50%) scale(0.7);
			}
		}
	}

	.swiper-pagination-bullet-active {
		border: 1px solid rgba($color_primary, 0.3);

		&::after {
			opacity: 1;
			transform: translate(-50%, -50%) scale(1);
		}

		&:hover {
			animation: none;
			&::after {
				transform: translate(-50%, -50%) scale(1);
			}
		}
	}

	@keyframes bulletsRotate {
		from {
			transform: rotateZ(0deg);
		}
		to {
			transform: rotateZ(360deg);
		}
	}
}
