// Reset the box-sizing, position and others
html {
	box-sizing: border-box;
	min-width: 320px;
}

*,
*:before,
*:after {
	box-sizing: inherit;
	// Fix some resize in flexbox elements
	min-width: 0;
	min-height: 0;
}

*,
*:active {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
	-webkit-tap-highlight-color: transparent !important;
}

::selection {
	color: white;
	// Fix Chrome selection
	background: rgba($color_primary, 0.99);
}

::-moz-selection {
	color: white;
	background: $color_primary;
}

*:focus {
	outline: none;
}
//
//*,
//html * {
//	scrollbar-width: thin;
//	scrollbar-color: rgba(#45983A, 0.74) rgba(#45983A, 0.16);
//}
//
//*::-webkit-scrollbar,
//html *::-webkit-scrollbar {
//	height: 8px;
//	width: 8px;
//}
//*::-webkit-scrollbar-track,
//html *::-webkit-scrollbar-track {
//		background: rgba(#45983A, 0.16);
//}
//*::-webkit-scrollbar-thumb,
//html *::-webkit-scrollbar-thumb {
//	background-color: rgba(#45983A, 0.74);
//	border: none;
//	border-radius: 20px;
//
//	&:hover {
//		background-color: #45983A;
//	}
//}

body {
	color: $color_body;
	font-family: $font_body;
	font-size: 16px;
	font-weight: normal;
	line-height: 1;
	background: $white;
	font-variant-numeric: lining-nums;
	-webkit-font-smoothing: antialiased;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	transition: background-color 0.4s ease-in-out;
}

// Links
a {
	color: inherit;
	text-decoration: none;
	transition: color 0.25s ease;

	&:hover {
		color: $color_primary;
		text-decoration: none;
	}
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	font-weight: inherit;
}

ol,
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
	display: block;
	vertical-align: middle;
}

img {
	max-width: 100%;
}

input[type='text'],
input[type='submit'],
textarea {
	-webkit-appearance: none;
	border-radius: 0;
}

.display_none {
	display: none !important;
}

@include tablet {
	.display_desktop {
		display: none !important;
	}
}

@media (min-width: 1024px) {
	.display_mobile {
		display: none !important;
	}
}

.preload * {
	transition: none !important;
}

@keyframes textCircleRotateAnimation {
	from {
		transform: rotateZ(0deg);
	}
	to {
		transform: rotateZ(360deg);
	}
}

[data-big-picture] {
	overflow: hidden;
	position: relative;

	img {
		position: absolute;
		display: block;
	}
}
[data-title-back] {
	span {
		display: block;
	}
}
