.nav-popover {
	$that: &;

	display: flex;
	padding: 44px 0px 0px 56px;

	&__bg {
		position: absolute;
		left: 0;
		top: 0;
	}

	&__item {
		margin-top: 17px;

		&:first-child {
			margin-top: 0;
		}
	}

	&__link {
		position: relative;
		display: inline-flex;
		justify-content: flex-start;
		align-items: flex-start;
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		text-transform: uppercase;
		color: #7b7b7b;
		position: relative;
		transition: color 0.6s cubic-bezier(0.25, 1, 0.5, 1);

		&::before {
			content: '';
			position: absolute;
			left: -14px;
			top: -14px;
			width: 41px;
			height: 41px;
			background: $color_primary;
			border-radius: 100%;
			z-index: 0;
			pointer-events: none;
			transform: scale(0);
			transition: transform 0.6s cubic-bezier(0.25, 1, 0.5, 1);
		}

		&:hover {
			color: $color_body;

			&::before {
				transform: scale(1);
			}
		}

		._number {
			font-weight: 500;
			font-size: 8px;
			line-height: 10px;
			color: inherit;
			padding-right: 9px;
			position: relative;
			z-index: 20;
		}

		._text {
			border-bottom: 1px solid;
			position: relative;
			z-index: 20;
		}
	}
}
