.content-block {
	$that: &;

	font-size: 16px;
	line-height: 1.825;

	> :first-child {
		margin-top: 0 !important;
	}

	> :last-child {
		margin-bottom: 0 !important;
	}

	h2 {
		margin-top: 56px;
		font-weight: 500;
		font-size: 28px;
		line-height: 1.2;

		@include tablet {
			margin-top: 40px;
		}
		@include phone {
			margin-top: 32px;
			font-size: 24px;
		}
	}

	h3 {
		margin-top: 56px;
		font-weight: 500;
		font-size: 24px;
		line-height: 1.2;

		@include tablet {
			margin-top: 40px;
		}
		@include phone {
			margin-top: 32px;
			font-size: 20px;
		}
	}

	h4 {
		margin-top: 56px;
		font-weight: 500;
		font-size: 20px;
		line-height: 1.2;

		@include tablet {
			margin-top: 40px;
		}
		@include phone {
			margin-top: 32px;
			font-size: 18px;
		}
	}

	p {
		font-weight: normal;
		margin-top: 24px;

		@include phone {
			font-size: 14px;
		}
	}

	p.first-letter-root {
		position: relative;

		.first-letter {
			position: absolute;
			top: 0;
			left: 0;
			transform: translate3d(-59%, -90px, 0);
			font-weight: 600;
			font-size: 219.826px;
			line-height: 268px;
			text-transform: uppercase;
			color: rgba(21, 22, 23, 0.08);

			@include tablet {
				transform: translate3d(-26%, -85px, 0);
			}

			@include phone {
				display: none;
			}
		}
	}

	a {
		color: $color_primary;

		&:hover {
			color: darken($color_primary, 15%);
		}
	}

	img {
		height: auto !important;
	}

	ol,
	ul {
		margin-top: 24px;
		font-size: 16px;
		list-style: none;
		list-style-type: none;

		@include phone {
			font-size: 14px;
		}

		li {
			position: relative;

			& + li {
				margin-top: 14px;
			}
		}
	}

	ul {
		li {
			padding-left: 24px;

			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 14px;
				transform: translateY(-50%);
				width: 8px;
				height: 8px;
				border-radius: 100%;
				background-color: $color_primary;
			}
		}
	}

	ol {
		counter-reset: section;

		li {
			padding-left: 24px;

			&:before {
				counter-increment: section;
				content: counter(section);
				position: absolute;
				top: 0;
				left: 0;
				color: #7a8084;
			}
		}
	}

	blockquote {
		margin: 56px 0;
		padding: 22px 56px;

		font-weight: 500;
		border-left: 4px solid $color_primary;

		@include tablet {
			margin: 40px 0;
		}

		@include phone {
			margin: 42px 0;
			padding: 16px 20px 16px 28px;
		}

		&.blockquote-primary {
			font-style: italic;
			padding: 62px 90px 50px;
			border: 1px dashed #e5e5e5;
			border-radius: 4px 4px 0 0;
			border-bottom: 3px solid $color_primary;
			position: relative;

			@include tablet {
				padding: 42px 40px;
			}

			@include phone {
				margin: 42px 0;
				padding: 32px 20px 32px 28px;
			}

			&::before,
			&::after {
				content: '';
				position: absolute;
				width: 29px;
				height: 24px;
				background: url($path_image + 'svg/quote_top.svg') no-repeat
					center;
				background-size: contain;
			}

			&::before {
				top: 32px;
				left: 36px;
				background: url($path_image + 'svg/quote_top.svg') no-repeat
					center;

				@include tablet {
					top: 10px;
					left: 16px;
				}
				@include phone {
					display: none;
				}
			}
			&::after {
				right: 50px;
				bottom: 76px;
				background: url($path_image + 'svg/quote_bottom.svg') no-repeat
					center;

				@include tablet {
					display: none;
				}
			}

			.blockquote-author {
				display: block;
				padding-top: 15px;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 24px;
			}
		}
	}

	&__video {
		position: relative;
		@include flex-center;
	}

	&__video-wrapper {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(
				360deg,
				rgba(34, 40, 45, 0.3763) 0%,
				rgba(34, 40, 45, 0) 75.27%
			);
		}

		&._cover-hidden:before {
			content: none;
		}

		video {
			width: auto !important;
			height: auto !important;
			min-width: 375px;
			max-width: 940px;
			min-height: 560px;
			max-height: 656px;
			margin: 0 auto;
			@include object-fit(cover, center);

			@include tablet {
				min-width: auto;
				max-width: 100%;
				min-height: calc(560 / 1023 * 100vw);
			}

			@include phone {
				min-height: auto;
			}
		}
	}

	&__video-button {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 150px;
		height: 150px;
		cursor: pointer;

		@include phone {
			width: 120px;
			height: 120px;
		}

		&:hover {
			#{$that}__video-circle {
				transform: scale(0.86) rotate(180deg);
			}

			// #{$that}__play-icon {
			// 	color: $color_primary;
			// }
		}

		&._hidden {
			display: none;
		}
	}

	&__video-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transition: color 0.8s cubic-bezier(0.35, 1, 0.5, 2);
		color: $white;
	}

	&__video-circle {
		transform: scale(1);
		transition: transform 1s cubic-bezier(0.25, 0.1, 0, 1);

		svg {
			transform-origin: center center;
			animation: textCircleRotateAnimation 14s infinite;

			@include phone {
				width: 120px;
				height: 120px;
			}
		}
	}
}
