.investors-page {
	$that: &;

	overflow: hidden;
	padding-bottom: 188px;
	position: relative;

	@include tablet {
		padding-bottom: 120px;
	}
	@include phone {
		padding-bottom: 70px;
	}

	&__hero {
		position: relative;
		padding-top: 114px;
		padding-bottom: 114px;
		background: #f9f9f9;

		@include laptop {
			height: 918px;
		}

		@include tablet {
			padding-top: 110px;
			padding-bottom: 110px;
		}
		@include phone {
			padding-top: 87px;
			padding-bottom: 87px;
			height: 750px;
		}
	}

	&__breadcrumbs {
		@extend %container;
		margin-top: 23px;

		@include laptop {
			position: relative;
			z-index: 20;

			.breadcrumbs__item {
				svg {
					color: $white;
				}

				span {
					color: #8b939a;
				}
			}
			.breadcrumbs__link {
				color: $white;
			}
		}

		@include phone {
			margin-top: 14px;
		}
	}

	&__hero-inner {
		@extend %container;

		@include laptop {
			height: 100%;
		}
	}

	&__hero-text {
		padding-top: 216px;
		position: relative;
		z-index: 20;

		@include laptop {
			color: $white;
			padding-top: 0;
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: center;
			align-items: center;
		}
		@include phone {
			flex-direction: column-reverse;
		}
	}

	&__subtitle {
		font-weight: 500;
		font-size: 24px;
		line-height: 29px;
		text-transform: uppercase;
		border-bottom: 1px solid;
		padding-bottom: 3px;
		display: inline;

		@include phone {
			font-size: 16px;
			line-height: 28px;
			border-bottom: none;
			text-decoration: underline;
			text-decoration-skip-ink: none;
			padding-bottom: 0;
			text-align: center;
		}
	}

	&__title {
		font-weight: 500;
		font-size: 72px;
		line-height: 76px;
		text-transform: uppercase;
		padding-top: 6px;

		@include tablet {
			padding-top: 15px;
		}
		@include phone {
			padding-top: 0px;
			padding-bottom: 20px;
			font-size: 28px;
			line-height: 40px;
		}
	}

	&__globe {
		width: 167px;
		height: 167px;
		margin: 153px 0 0;
		position: relative;
		@include flex-center();

		@include laptop {
			display: none;
		}
	}

	&__globe-icon {
		box-shadow: 0px 10px 61px rgba(163, 184, 190, 0.25);
		border-radius: 100%;
		position: absolute;

		svg {
			width: 84px;
			height: 84px;

			@include phone {
				width: 62px;
				height: 62px;
			}
		}
	}

	&__globe-text {
		position: absolute;

		svg {
			width: 100%;
			height: 100%;
			color: rgba($color_body, 0.4);
			animation: textCircleRotateAnimation 28s linear;
		}
	}

	&__arrow-down {
		position: absolute;
		top: calc(100% - 60px);

		@include laptop {
			display: none;
		}
	}

	&__hero-picture {
		position: absolute;
		right: 0;
		top: 190px;
		height: 804px;
		width: 940px;
		overflow: hidden;

		@include laptop {
			top: 0;
			width: 100%;
			height: 100%;

			&::before {
				content: '';
				position: absolute;
				z-index: 1;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(
						180deg,
						rgba(11, 14, 26, 0.48) 0.91%,
						rgba(11, 14, 26, 0) 40.16%
					),
					linear-gradient(
						0deg,
						rgba(11, 14, 26, 0.4),
						rgba(11, 14, 26, 0.4)
					);
				pointer-events: none;
			}
		}

		img {
			width: 100%;
			height: 100%;

			@include object-fit(cover, top left);
			@include lazy-loaded;
		}
	}

	&__container {
		@extend %container;
		position: relative;

		@include laptop {
			background: $white;
		}
	}

	&__body {
		position: relative;
		z-index: 20;
		padding-top: 276px;

		@include laptop {
			padding-top: 84px;
		}

		@include phone {
			padding-top: 56px;
		}
	}

	&__desc {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		padding-bottom: 130px;

		@include laptop {
			padding-bottom: 89px;
		}
		@include phone {
			padding-bottom: 69px;
		}
	}

	&__desc-left {
		padding: 0 53px;
		font-weight: 500;
		font-size: 38px;
		line-height: 64px;
		text-align: right;
		text-transform: uppercase;
		flex: 1 1 0%;

		@include laptop {
			text-align: center;
			padding: 0;
		}
		@include phone {
			font-size: 24px;
			line-height: 36px;
		}

		._text {
			max-width: 788px;
			margin-left: auto;

			@include laptop {
				margin: 0 auto;
			}
		}

		span {
			border-bottom: 2px solid;
		}
	}

	&__desc-right {
		padding: 0 53px;
		font-weight: 500;
		font-size: 18px;
		line-height: 38px;
		flex: 1 1 0%;

		@include laptop {
			display: none;
		}

		._text {
			max-width: 464px;
		}
	}

	&__picture {
		height: 732px;

		@include laptop {
			height: 548px;
		}

		@include tablet {
			height: auto;
		}

		img {
			width: 100%;
			height: 100%;

			@include object-fit(cover, center);
			@include lazy-loaded;
		}
	}

	&__slider {
		padding-top: 199px;

		@include laptop {
			padding-top: 101px;
		}

		@include tablet {
			padding-top: 49px;
		}
	}
}
