.responsibility-swiper {
	$that: &;

	&__swiper-container.swiper-container {
		overflow: visible;
	}

	&__swiper-wrapper.swiper-wrapper {
		@include tablet {
			display: block;
		}
	}

	&__swiper-slide.swiper-slide {
		width: 1074px;
		height: 706px;

		@include desktop {
			width: 100%;
			height: 600px;
		}

		@include laptop {
			height: 496px;
		}

		@include tablet {
			height: auto;
			padding-bottom: 67px;
			padding-top: 70px;
			border-bottom: 1px solid rgba($black, 0.1);

			&:first-child {
				padding-top: 0;
			}
			&:last-child {
				padding-bottom: 0;
				border-bottom: none;
			}
		}

		@include phone {
			// width: 257px;
		}

		&:nth-child(1) {
			#{$that}__picture {
				max-width: 336px;
				margin-top: -105px;

				@include desktop {
					max-width: 285px;
				}

				@include laptop {
					max-width: 245px;
				}

				@include tablet {
					max-width: 150px;
					margin-top: 0;
					top: -45px;
				}
			}

			#{$that}__desc {
				@include laptop {
					top: 80px;
					left: 410px;

					p {
						&:first-child {
							margin-left: -30px;
						}
					}
				}

				@include tablet {
					p {
						&:first-child {
							margin-left: 0px;
						}
					}
				}
			}
		}
		&:nth-child(2) {
			#{$that}__picture {
				max-width: 600px;

				@include desktop {
					max-width: 480px;
				}

				@include laptop {
					max-width: 400px;
				}

				@include tablet {
					max-width: 310px;
					margin-top: 0;
					top: -28px;
				}
			}

			#{$that}__desc {
				@include laptop {
					top: 58px;
					left: 440px;
					max-width: 290px;

					p {
						&:first-child {
							margin-left: -30px;
						}
					}
				}

				@include tablet {
					max-width: none;

					p {
						&:first-child {
							margin-left: 0px;
						}
					}
				}
			}
		}
		&:nth-child(3) {
			#{$that}__picture {
				max-width: 590px;
				margin-top: -110px;

				@include desktop {
					max-width: 490px;
					margin-top: -90px;
				}

				@include laptop {
					max-width: 394px;
					margin-top: -68px;
				}

				@include tablet {
					max-width: 295px;
					margin-top: 0;
					top: -55px;
				}
			}

			#{$that}__desc {
				@include desktop {
					top: 160px;
				}
				@include laptop {
					top: 130px;
					left: 455px;
				}
			}
		}
	}

	&__item {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		width: 706px;
		height: 100%;
		margin: 0 auto;
		position: relative;

		@include desktop {
			width: 600px;
		}
		@include laptop {
			width: 496px;
		}

		@include tablet {
			width: 100%;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
		}
	}

	&__bg {
		position: absolute;
		width: 100%;
		height: 100%;
		will-change: transform;

		@include tablet {
			width: 316px;
			height: 316px;
			position: static;
		}

		svg {
			width: 100%;
			height: 100%;
		}
	}

	&__picture {
		position: relative;
		will-change: transform;

		@include tablet {
			position: absolute;
		}

		img {
			width: 100%;
			height: 100%;

			@include object-fit(contain, center);
			@include lazy-loaded;
			will-change: transform;
		}
	}

	&__ecology {
		font-weight: 600;
		font-size: 14px;
		line-height: 32px;
		text-align: center;
		letter-spacing: 2px;
		text-transform: uppercase;
		color: $white;
		position: absolute;
		bottom: 48px;

		@include laptop {
			bottom: 24px;
		}
		@include tablet {
			display: none;
		}
	}

	&__title {
		font-weight: 500;
		font-size: 24px;
		line-height: 38px;
		color: $white;
		text-transform: uppercase;

		position: absolute;
		top: 185px;
		right: 603px;
		width: 100%;
		max-width: 430px;
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;
		will-change: transform;

		@include desktop {
			flex-direction: column;
			align-items: flex-end;
			top: 160px;
			right: 515px;
			max-width: none;
			margin-top: -5px;
		}

		@include laptop {
			top: 130px;
			right: 424px;
		}

		@include tablet {
			position: static;
			padding-top: 50px;
			flex-direction: row;
			align-items: flex-start;
			justify-content: center;
		}

		span {
			padding: 0 8px;
			background: $color_primary;
			margin-top: 2px;

			@include desktop {
				margin-top: 5px;
			}
		}
	}

	&__desc {
		font-weight: 500;
		font-size: 16px;
		line-height: 28px;

		position: absolute;
		top: 185px;
		left: 636px;
		width: 100%;
		max-width: 422px;
		text-align: left;
		will-change: transform;

		@include desktop {
			top: 95px;
			left: 525px;
			max-width: 310px;
		}

		@include tablet {
			position: static;
			font-size: 14px;
			line-height: 26px;
			padding-top: 30px;
			max-width: none;
			text-align: center;
		}

		p + p {
			padding-top: 28px;

			@include tablet {
				padding-top: 20px;
			}
		}
	}

	&__bottom {
		padding-top: 64px;
		max-width: 300px;
		margin: 0 auto;
		position: relative;

		@include tablet {
			display: none;
		}
	}

	&__progressbar-icon {
		position: absolute;
		bottom: -12px;
		left: -13.5px;
		width: 100%;
		height: 27px;
		z-index: 20;

		._progressbar-icon {
			width: 27px;
			height: 27px;
			background: $white;
			border: 2px solid $color_primary;
			border-radius: 100%;
		}
	}

	&__swiper-pagination.swiper-pagination {
		position: static;
		max-width: 300px;
		margin: 0 auto;
		background: rgba(55, 59, 66, 0.22);
		height: 2px;

		.swiper-pagination-progressbar-fill {
			background: $color_primary;
		}
	}
}
