.news-page {
	$that: &;

	overflow: hidden;
	padding-top: 114px;
	padding-bottom: 88px;
	position: relative;

	@include tablet {
		padding-top: 110px;
		padding-bottom: 78px;
	}
	@include phone {
		padding-top: 87px;
		padding-bottom: 58px;
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 683px;
		background: #f9f9f9;
		pointer-events: none;
		z-index: -1;

		@include tablet {
			display: none;
		}
	}

	&__container {
		@extend %container;
		position: relative;
	}

	&__breadcrumbs {
		margin-top: 23px;

		@include phone {
			margin-top: 14px;
		}
	}

	&__title {
		padding-top: 17px;
		padding-bottom: 87px;
		font-weight: 500;
		font-size: 72px;
		line-height: 88px;
		text-align: center;
		text-transform: uppercase;

		@include tablet {
			font-size: 62px;
			line-height: 76px;
			padding-top: 15px;
			padding-bottom: 80px;
		}
		@media (max-width: 767px) {
			font-size: 28px;
			line-height: 34px;
		}
		@include phone {
			padding-top: 38px;
			padding-bottom: 35px;
		}
	}

	&__body {
		position: relative;
		z-index: 20;

		@include laptop {
		}

		@include phone {
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: -56px 0 0 -46px;

		@include laptop {
			margin: -56px 0 0 -36px;
		}
	}

	&__item {
		flex: 0 1 33.333%;
		padding: 56px 0 0 46px;
		display: block;
		min-height: 638px;

		@include desktop {
			flex: 0 1 50%;
		}

		@include laptop {
			padding: 56px 0 0 36px;
		}

		@media (max-width: 767px) {
			flex: none;
			width: 100%;
		}
		@include phone {
			min-height: 487px;

			.news-item__picture {
				min-height: 210px;
			}
		}
	}

	&__pagination {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 88px;

		a {
			width: 50px;
			height: 50px;
			border-radius: 4px;
			@include flex-center();
		}
	}

	&__pagination-arr {
		background: #f6f6f6;
		flex: none;

		svg {
			stroke: $color_body;
		}

		&:hover {
			svg {
				stroke: $color_primary;
			}
		}

		&[rel='prev'] {
			margin-right: 35px;

			@include phone {
				margin-right: 16px;
			}
		}
		&[rel='next'] {
			margin-left: 35px;

			@include phone {
				margin-left: 16px;
			}
		}
	}

	&__pagination-item {
		font-weight: 500;
		font-size: 18px;
		line-height: 36px;

		&._is-active {
			background: $color_primary;
			color: $white;
			flex: none;
		}
	}
}
