.modal-content {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 80px 30px;
	z-index: 9011;
	opacity: 0;
	overflow: auto;
	visibility: hidden;
	transform: translate3d(0, -20px, 0);
	transition: opacity 0.25s ease-out, visibility 0.25s ease-out,
		transform 0.25s ease-out;

	._is-ie & {
		display: block;
	}

	.modal_open & {
		visibility: visible;
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	.modal_closing & {
		transition: opacity 0.25s ease-out, visibility 0.25s ease-out,
			transform 0.25s ease-out;
		transform: translate3d(0, 20px, 0);
		opacity: 0;
	}

	._premium & {
		background-color: $color_body;
	}
}

.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9010;
	background-color: rgba(31, 31, 31, 0.46);
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.25s ease-out, visibility 0.25s ease-out;

	.modal_open & {
		opacity: 1;
		visibility: visible;
	}

	.modal_closing & {
		transition: opacity 0.25s ease-out, visibility 0.25s ease-out;
		opacity: 0;
	}
}
