.layout {
	$that: &;

	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow: hidden;

	&__header {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 100;
		flex: none;
		will-change: transform;

		.header_hidden & {
			transform: translate3d(0, -101%, 0);
			transition: transform 0.85s ease-in-out;
		}

		.header_hidden.page_scroll-up & {
			transform: translate3d(0, 0, 0);
			transition: transform 0.6s ease-in-out;
		}
	}

	&__content {
		display: flex;
		height: 100%;

		> * {
			flex: 1 1 auto;
		}

		.page_secondary & {
			padding-top: 114px;

			@include tablet {
				padding-top: 110px;
			}
			@include phone {
				padding-top: 87px;
			}
		}
	}

	.scroller[data-scrollbar] {
		height: 100vh;
	}

	.scroller-wrap {
		overflow: hidden;
	}

	.scroll-content {
		display: flex;
		flex-direction: column;
		will-change: transform;
	}

	.scrollbar-track {
		z-index: 9999;
	}

	.scrollbar-thumb {
		background-color: rgba(#45983a, 0.74);
	}
}
