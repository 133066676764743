.about-history {
	position: relative;

	&__back {
		font-weight: normal;
		font-size: 200px;
		line-height: 243px;
		text-align: center;
		text-transform: uppercase;
		color: rgba(21, 22, 23, 0.02);

		position: absolute;
		top: -145px;
		left: 50%;
		transform: translateX(-50%);

		@include tablet {
			font-size: 19vw;
		}
		@include phone {
			font-size: 60px;
			line-height: 73px;
			top: -35px;
		}
	}

	&__title {
		font-weight: 500;
		font-size: 72px;
		line-height: 88px;
		text-align: center;
		text-transform: uppercase;

		@include tablet {
			font-size: 52px;
			line-height: 63px;
		}
		@include phone {
			font-size: 42px;
			line-height: 51px;
		}
	}

	&__body {
		padding-top: 130px;

		@include tablet {
			padding-top: 30px;
		}
	}

	&__main {
		position: relative;
		padding-bottom: 167px;

		@include tablet {
			padding-bottom: 61px;
		}
		@include phone {
			padding-bottom: 54px;
		}
	}

	&__start {
		@include flex-center();
		position: relative;
		width: 451px;
		height: 451px;
		margin: 0 auto;

		@include desktop {
			width: 100%;
			height: auto;
			margin: 0;
			flex-direction: column;
		}
	}

	&__text {
		position: absolute;
		font-weight: 500;
		font-size: 24px;
		line-height: 42px;
		text-transform: uppercase;

		@include desktop {
			position: static;
		}
		@include phone {
			font-size: 20px;
			line-height: 38px;
		}

		&._start-top {
			max-width: 468px;
			width: 115%;
			text-align: right;
			top: 0;
			right: 100%;
			padding-right: 28px;
			padding-top: 50px;

			@include desktop {
				padding: 0;
				width: 100%;
				text-align: center;
				max-width: 618px;
				padding-bottom: 36px;
			}
			@include phone {
				padding-bottom: 54px;
			}
		}

		&._start-bottom {
			width: 120%;
			max-width: 538px;
			text-align: left;
			bottom: 0;
			left: 100%;
			display: flex;
			flex-wrap: wrap;
			color: $white;
			padding-left: 22px;
			padding-bottom: 20px;

			@include desktop {
				padding: 0;
				width: 100%;
				max-width: 516px;
				text-align: center;
				justify-content: center;
				padding-top: 36px;
			}
			@include phone {
				padding-top: 30px;
			}

			span {
				padding: 2px 4px;
				margin-top: 5px;
				background: $color_primary;
			}
		}
	}

	&__year {
		position: relative;
		@include flex-center();

		@include phone {
			width: 281px;
		}

		._year {
			position: absolute;
			font-weight: 500;
			font-size: 101px;
			line-height: 124px;
			text-transform: uppercase;
			color: $white;

			@include phone {
				font-size: 63px;
				line-height: 77px;
			}

			span {
				font-size: 18px;
				line-height: 22px;

				@include phone {
					font-size: 11px;
					line-height: 14px;
				}
			}
		}
	}

	&__desc {
		padding-top: 194px;
		font-weight: 500;
		font-size: 24px;
		line-height: 42px;
		text-align: center;
		text-transform: uppercase;
		max-width: 1143px;
		margin: 0 auto;

		@include tablet {
			display: none;
		}
	}

	&__arrow-down {
		position: absolute;
		right: 0;
		bottom: -80px;
		width: 269px;
		height: 353px;

		@include desktop {
			display: none;
		}

		svg {
			width: 100%;
			height: 100%;
		}
	}

	&__now {
		display: flex;
		align-items: center;

		@include tablet {
			flex-direction: column;
			justify-content: center;
		}
	}

	&__picture {
		width: 50%;
		overflow: hidden;

		@include tablet {
			width: 100%;
		}
		@include phone-s {
			height: 227px;
		}

		img {
			width: 100%;
			height: 100%;

			@include object-fit(cover, center);
			@include lazy-loaded;
		}
	}

	&__now-text {
		font-weight: 500;
		font-size: 24px;
		line-height: 42px;
		text-align: center;
		text-transform: uppercase;
		max-width: 641px;
		padding-left: 30px;
		width: 50%;
		margin: 0 auto;

		@include tablet {
			width: 100%;
			max-width: 611px;
			padding-left: 0;
			padding-top: 81px;
		}
		@include phone {
			font-size: 20px;
			line-height: 38px;
			padding-top: 46px;
		}

		span {
			color: $white;
			white-space: nowrap;
			background: $color_primary;
			padding: 8px 20px;
		}
	}
}
