.hevel-swiper {
	$that: &;
	position: relative;

	.ecotourism-page__desc-title {
		@include tablet {
			display: none;
		}
	}

	&__swiper-container.swiper-container {
		overflow: visible;
	}

	&__swiper-wrapper.swiper-wrapper {
		@media (min-width: 1024px) {
			justify-content: center !important;
		}

		@include tablet {
			display: block;
		}
	}

	&__swiper-slide.swiper-slide {
		&._slide-first {
			max-width: 1420px;

			@include tablet {
				max-width: 704px;
				margin: 0 auto;
			}

			@include phone {
				max-width: none;
			}

			#{$that}__item {
				display: flex;
				justify-content: center;

				@include tablet {
					justify-content: flex-end;
				}
				@include phone {
					display: block;
				}
			}
		}

		&._slide-second {
			@media (min-width: 1024px) {
				display: none;
			}

			@include tablet {
				max-width: none;
			}

			#{$that}__item {
				@include tablet {
					display: flex;
					justify-content: center;
					align-items: flex-start;
					padding-top: 135px;
					width: 100%;
				}
				@include phone {
					display: block;
					padding-top: 0;
				}
			}

			#{$that}__text {
				position: static;

				@include tablet {
					&:first-child {
						padding-right: 56px;
						text-align: right;

						@include phone {
							padding-right: 0;
							text-align: center;
						}
					}
					&:last-child {
						padding-left: 56px;

						@include phone {
							padding-left: 0;
						}
					}
				}
			}
		}

		@include phone {
		}
	}

	&__item {
		position: relative;
		justify-content: center !important;

		@include tablet {
			justify-content: flex-end !important;
		}
	}

	&__title {
		font-weight: 500;
		font-size: 38px;
		line-height: 46px;
		text-align: right;
		color: $white;
		text-transform: uppercase;
		max-width: 495px;
		display: inline;
		position: absolute;
		top: 175px;
		left: 140px;
		z-index: 20;
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;
		will-change: transform;

		@media (min-width: 1024px) {
			display: none;
		}

		@include laptop {
			top: 25px;
		}
		@include tablet {
			top: 175px;
			left: -20px;
		}
		@include phone {
			position: static;
			justify-content: center;
			font-size: 24px;
			line-height: 29px;
			margin-top: -24px;
		}

		span {
			background: #3aa222;
			padding: 5px 10px;
			margin-top: 3px;
		}
	}

	&__text {
		font-weight: 500;
		font-size: 18px;
		line-height: 38px;
		max-width: 487px;

		//position: absolute;
		padding-left: 120px;

		right: 0;
		top: 165px;
		z-index: 20;
		will-change: transform;

		p + p {
			padding-top: 48px;
		}

		@include tablet {
			padding-left: 0;
			max-width: none;
			width: 50%;
		}

		@include phone {
			font-size: 14px;
			line-height: 28px;
			text-align: center;
			padding-top: 28px;
			width: 100%;
		}
	}

	&__picture {
		width: 578px;
		will-change: transform;
		overflow: hidden;

		@include tablet {
			width: 441px;
		}

		@include phone {
			width: 100%;
		}

		img {
			width: 100%;
			height: 100%;

			@include object-fit(cover, center);
			@include lazy-loaded;
			will-change: transform;
		}
	}

	&__sun {
		width: 147px;
		height: 147px;
		position: absolute;
		bottom: 0;
		right: 0;
		will-change: transform;

		@include tablet {
			right: 374px;
			bottom: -68px;
		}

		@include phone {
			width: 81px;
			height: 81px;
			position: static;
			margin: 37px auto 0;
		}

		img {
			width: 100%;
			height: 100%;

			@include object-fit(contain, center);
			@include lazy-loaded;
		}
	}
}
