.footer {
	$that: &;
	position: relative;
	overflow: hidden;
	z-index: 30;

	&__main {
		display: flex;
		min-height: 366px;

		@include phone {
			display: block;
			min-height: auto;
		}
	}

	&__info {
		flex: none;
		padding: 48px 44px 26px 36px;
		background: $color_primary;
		color: $white;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;

		display: flex;
		flex-direction: column;
		width: 24.43%;

		@include tablet {
			width: 45.6%;
			padding: 45px 30px 26px;
		}

		@include phone {
			width: 100%;
			align-items: center;
			padding: 43px 30px 41px;
		}
	}

	&__logo {
		padding-bottom: 37px;
		border-bottom: 1px solid rgba($white, 0.2);

		@include phone {
			width: 100%;
			display: flex;
			justify-content: center;
		}

		.logo svg {
			width: 155px;
			height: 51px;
		}
	}

	&__phone {
		margin-top: 33px;

		@include phone {
			margin-top: 38px;
		}

		&:hover {
			color: $white;
		}
	}

	&__email {
		margin-top: 17px;

		&:hover {
			color: $white;
		}
	}

	&__copyright {
		flex-grow: 1;
		display: inline-flex;
		flex-direction: column;
		justify-content: flex-end;

		@include phone {
			display: none;
		}
	}

	&__body {
		display: flex;
		width: 100%;
		background: #efefef;

		@include tablet {
			flex-direction: column;
		}
		@include phone {
			background: $white;
		}
	}

	&__nav {
		flex: 1 1 0%;
		padding: 74px 24px 0 107px;

		@include laptop {
			padding: 74px 24px 0;
		}

		@include tablet {
			max-width: none;
			padding: 22px 30px 0px 63px;
		}

		@media (max-width: 767px) {
			padding-left: 30px;
		}

		@include phone {
			padding: 18px 30px 40px;
		}
	}

	&__nav-columns {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		max-width: 825px;
	}

	&__nav-column {
		max-width: 25%;
		padding-left: 24px;

		&:first-child {
			max-width: 20%;
			padding-left: 0;

			@include tablet {
				max-width: 50%;
			}
			@include phone {
				max-width: 100%;
			}
		}

		&:nth-child(2) {
			max-width: 30%;

			@include tablet {
				max-width: 50%;
			}
			@include phone {
				max-width: 100%;
			}
		}

		@include tablet {
			width: 50%;
			max-width: 50%;
			padding: 35px 0 0;

			&:first-child {
				margin-top: 0;
				border-top: none;
			}
		}

		@include phone {
			width: 100%;
			max-width: 100%;
			border-top: 1px solid #e5e5e5;
			padding: 32px 0 0;
			margin-top: 24px;

			&:last-child {
				border-top: none;
				padding-top: 0;
			}

			&:nth-child(3),
			&:nth-child(4) {
				.nav-footer__item {
					margin-bottom: 0;
				}
			}
		}
	}

	&__develop {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		padding-top: 74px;
		padding-right: 36px;
		padding-bottom: 26px;

		@include tablet {
			height: auto;
			display: block;
			padding-top: 53px;
			padding-right: 30px;
			text-align: right;
		}

		@include phone {
			display: none;
		}

		svg {
			width: 168px;
			height: 168px;

			@include tablet {
				display: none;
			}
		}

		a {
			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
			color: #656d72;

			&:hover {
				@media (min-width: 1025px) {
					color: $color_primary;
				}
			}
		}
	}

	&__bottom-mobile {
		padding: 27px 0 28px;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: $color_body;
		background: #f3f3f3;

		@media (min-width: 640px) {
			display: none;
		}
	}

	&__bottom-body {
		@extend %container;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__bottom-copyright {
	}

	&__bottom-develop {
		opacity: 0.32;

		a {
			text-decoration: underline;
		}
	}
}
