.contact-block {
	$that: &;

	position: relative;
	padding: 112px 144px;

	@include tablet {
		padding-top: 48px;
		padding-bottom: 48px;
	}

	@include phone {
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 16px;
	}

	&__wrap {
		//max-width: 992px;

		margin: 0 auto;
		overflow: hidden;
	}

	&__row {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-left: -48px;
		margin-right: -48px;
	}

	&__column {
		position: relative;
		flex: 1 1 0%;
		padding-top: 12px;
		padding-left: 46px;
		padding-right: 46px;

        &:nth-of-type(2) {
            flex: 0 0 412px;
        }

		@include laptop {
			flex: 1 1 50%;
			margin-top: 32px;
		}

		@include tablet {
			flex: 1 1 100%;
		}

		@include phone {
			padding-top: 0;

			&:first-child {
				margin-top: 0;
			}
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 1px;
			height: 56px;
			background-color: rgba(0, 0, 0, 0.1);
		}
	}

	&__item {
		display: flex;

		@include tablet {
			flex-direction: column;
			align-items: center;
			text-align: center;
		}
	}

	&__icon {
		flex: none;
		margin-right: 16px;

		@include tablet {
			margin-right: 0;
		}
	}

	&__info {
		margin-top: 4px;

		@include tablet {
			margin-top: 6px;
		}
	}

	&__name {
		font-size: 18px;
		font-weight: 500;
		line-height: 1.222222222;
	}

	&__text {
		margin-top: 8px;
		color: rgba(21, 22, 23, 0.7);
		font-size: 14px;
		font-weight: 500;
		line-height: 1.857142857;
	}

	&__left-decor {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-32%);

		@include desktop {
			left: -48px;
		}

		@include phone {
			display: none;
		}
	}

	&__right-decor {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-32%);

		@include desktop {
			right: -48px;
		}

		@include phone {
			display: none;
		}
	}
}
