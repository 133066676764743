.logo {
	$that: &;

	display: inline-block;
	vertical-align: middle;
	color: inherit;

	&._color_black {
		color: #000;
	}

	&._color_white {
		color: #fff;
	}

	&__svg {
		max-width: 100%;
		height: auto;
	}
}
