.close-modal {
	$that: &;

	appearance: none;
	user-select: none;
	cursor: pointer;

	&__svg {
		display: block;
		width: 47px;
		height: 47px;
		padding: 10px 12px;
		color: $white;
		box-sizing: content-box;
		transition: color 0.25s ease-out;

		@include tablet {
			color: #c4c4c4;
		}

		&:hover {
			color: $color_primary;
		}
	}
}
