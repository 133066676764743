.menu {
	$that: &;

	position: fixed;
	top: 0;
	left: 0;
	z-index: 500;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background: $white;
	transform: translate3d(-100%, 0, 0);
	pointer-events: none;
	transition: opacity 0.6s cubic-bezier(0, 0.55, 0.45, 1),
		transform 0.6s cubic-bezier(0, 0.55, 0.45, 1);
	display: none;

	@include laptop {
		display: flex;
	}

	.menu_open & {
		pointer-events: all;
		transform: translate3d(0, 0, 0);
	}

	&__header {
		flex: none;
		display: flex;
		align-items: center;
		@extend %container;

		.header__svg-logo {
			fill: $black;

			path._path-green {
				fill: $color_primary;
			}
		}

		.header__burger {
			svg {
				fill: $color_body;
			}
		}

		.header__lng {
			border-color: rgba($black, 0.04);
		}

		.header__lng-toggle {
			color: $black;

			svg {
				color: rgba($black, 0.38);
			}
		}
	}

	&__actions {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-left: auto;
	}

	&__close {
		margin-left: 36px;
	}

	&__button-close {
		padding: 0;
		color: $color_body;
		background: transparent;
		border: 0;
	}

	&__main {
		flex: 1 1 0%;
		display: flex;
		flex-direction: column;
		overflow: auto;
	}

	&__body {
		flex: none;
		padding-top: 16px;
		@extend %container;
	}

	&__list {
	}

	&__item {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-top: 22px;

		&:first-child {
			margin-top: 0;
		}
	}

	&__link {
		flex: 1 1 0%;
		color: $color_body;
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		text-transform: uppercase;
	}

	&__arrow {
		margin-right: 14px;

		.arrow {
			width: 26px;
			height: 26px;

			svg {
				width: 11px;
				height: 9px;
			}
		}
		.arrow__icon {
			z-index: auto;
		}
	}

	&__sub {
		position: fixed;
		top: 87px;
		left: 0;
		width: 100%;
		background: #fff;
		height: 100%;
		opacity: 0;
		pointer-events: none;
		transform: translate3d(-100%, 0, 0);
		transition: opacity 0.6s cubic-bezier(0, 0.55, 0.45, 1),
			transform 0.6s cubic-bezier(0, 0.55, 0.45, 1);

		&.submenu_open {
			opacity: 1;
			pointer-events: all;
			transform: translate3d(0, 0, 0);
		}
	}
}
