.content-page {
	$that: &;

	background: #f9f9f9;
	padding-top: 114px;

	@include tablet {
		padding-top: 110px;
	}
	@include phone {
		padding-top: 87px;
		background: $white;
	}

	&__container {
		@extend %container;
		position: relative;
	}

	&__breadcrumbs {
		margin-top: 23px;

		@include phone {
			margin-top: 14px;

			.breadcrumbs__item {
				display: none;

				&:nth-child(2) {
					display: flex;
				}
			}
		}
	}

	&__body {
		padding-top: 27px;
		padding-bottom: 130px;

		@include tablet {
			padding-bottom: 88px;
		}
		@include phone {
			padding-top: 22px;
			padding-bottom: 47px;
		}
	}

	&__title-block {
		width: 100%;
		max-width: 1348px;
		margin: 0 auto;
		position: relative;
		@include flex-center();

		@include phone {
			display: block;
		}
	}

	&__title-picture {
		position: relative;
		border-radius: 4px 4px 0px 0px;
		overflow: hidden;

		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background: linear-gradient(
				0deg,
				rgba(0, 0, 0, 0.4),
				rgba(0, 0, 0, 0.4)
			);

			@include phone {
				display: none;
			}
		}

		@include phone {
			max-height: 208px;
		}

		img {
			width: 100%;
			height: 100%;

			@include phone {
				height: auto;
			}

			@include object-fit(cover, center);
			@include lazy-loaded;
		}
	}

	&__title {
		font-weight: 500;
		font-size: 32px;
		line-height: 39px;
		text-align: center;
		text-transform: uppercase;
		color: $white;
		padding: 0 16px;
		position: absolute;

		@include phone {
			position: static;
			font-size: 24px;
			line-height: 36px;
			color: $color_body;
			padding: 31px 0 0;
		}
	}

	&__main {
		margin-top: -72px;

		@include tablet {
			margin-top: 0;
		}
	}

	&__similar {
		padding-top: 105px;
		padding-bottom: 130px;
		background: $white;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 46.2%;
			background: $color_primary;

			@include phone {
				height: 145px;
			}
		}

		@include tablet {
			padding: 88px 0;
		}
		@include phone {
			padding: 36px 0 47px;
		}
	}

	&__similar-title {
		font-weight: 500;
		font-size: 32px;
		line-height: 39px;
		text-align: center;
		text-transform: uppercase;
		color: $white;

		@include phone {
			font-size: 24px;
			line-height: 29px;
		}
	}

	&__similar-body {
		padding-top: 90px;

		@include tablet {
			padding-top: 88px;
		}
		@include phone {
			padding-top: 36px;
		}
	}
}
