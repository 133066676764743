.investors {
	$that: &;

	padding-bottom: 171px;
	position: relative;

	@include tablet {
		padding-bottom: 80px;
	}

	@include phone {
		padding-bottom: 64px;
	}

	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		background: #f3f3f3;
		width: 100%;
		height: calc(100% - 306px);
		z-index: 0;
		pointer-events: none;

		@include desktop {
			height: calc(100% - 374px);
		}
		@include tablet {
			height: calc(100% - 158px);
		}
		@include phone {
			height: calc(100% - 125px);
		}
	}

	&__inner {
		@extend %container;
		position: relative;

		@media (min-width: 1024px) {
			max-width: none;
			padding-left: 0;
		}
	}

	&__body {
		margin-bottom: 56px;
		padding-top: 83px;
		display: flex;
		justify-content: flex-start;

		@include desktop {
			justify-content: flex-start;
		}

		@include tablet {
			padding-top: 52px;
			display: block;
		}

		@include phone {
			padding-top: 26px;
		}
	}

	&__picture {
		position: relative;
		width: 49.74%;
		height: 585px;
		overflow: hidden;

		@media (min-width: 1920px) {
			height: auto;
		}

		@include desktop {
			width: 60%;
			max-width: 937px;
		}

		@include tablet {
			width: 100%;
			max-width: none;
			height: auto;
		}

		img {
			width: 100%;
			height: 100%;

			@include object-fit(cover, top right);
			@include lazy-loaded;
		}
	}

	&__main {
		padding-top: 63px;
		padding-left: 9%;
		margin-left: 30px;

		@media (min-width: 1920px) {
			padding-left: 160px;
		}

		@include desktop {
			width: auto;
			padding-top: 0;
		}

		@include laptop {
			padding-left: 30px;
			margin-left: 0;
		}

		@include tablet {
			padding-left: 0;
			padding-top: 52px;
			text-align: center;
		}
		@include phone {
			padding-top: 32px;
		}
	}

	&__main-title {
		font-weight: 500;
		font-size: 24px;
		line-height: 38px;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: $color_primary;
		padding-bottom: 6px;
		border-bottom: 1px solid;
		display: inline;
		max-width: 516px;

		@include tablet {
			max-width: 727px;
		}
		@include phone {
			padding-bottom: 2px;
		}
	}

	&__main-text {
		font-weight: 500;
		font-size: 18px;
		line-height: 38px;
		padding-top: 25px;
		padding-bottom: 124px;
		max-width: 516px;

		@include tablet {
			max-width: 727px;
			padding-bottom: 63px;
			margin: 0 auto;
		}
		@include phone {
			font-size: 14px;
			line-height: 26px;
			padding-bottom: 40px;
		}
	}

	&__link {
		@include flex-center();
		position: relative;
		width: 160px;
		height: 160px;

		@include tablet {
			margin: 0 auto;
		}

		@include phone {
			width: 123px;
			height: 123px;
		}

		&:hover {
			@include laptop-hover {
				._arrow {
					svg {
						fill: $color_primary;
					}
				}

				._circle {
					color: $color_primary;
					transform: scale(0.86);
				}
			}
		}

		._arrow {
			position: absolute;

			svg {
				stroke: $color_primary;
				fill: transparent;
				transition: fill 1s cubic-bezier(0.25, 0.1, 0, 1);

				@include phone {
					stroke: $color_body;
					fill: $color_body;
				}
			}
		}

		._circle {
			color: $color_body;
			width: 100%;
			height: 100%;
			transform: scale(1);
			will-change: transform;
			transition: transform 1s cubic-bezier(0.25, 0.1, 0, 1),
				color 1s cubic-bezier(0.25, 0.1, 0, 1);

			svg {
				width: 100%;
				height: 100%;
				transform-origin: center center;
				animation: textCircleRotateAnimation 28s linear;
			}
		}
	}

	&__chart {
		width: 100%;
		max-width: 1510px;
		margin: 0 auto;
		margin-top: 88px;
		padding: 71px 90px 53px 77px;
		background-color: $white;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 2px;
		overflow-x: scroll;
		overflow-y: hidden;

		&::-webkit-scrollbar {
			height: 4px; //для горизонтального скролла
		}
		&::-webkit-scrollbar-track {
			background: transparent;
			border-radius: 10px;
		}
		&::-webkit-scrollbar-thumb {
			bottom: 5px;
			background-color: $color_primary;
			border: none;
			border-radius: 10px;
		}

		@include desktop {
			margin-left: 18px;
		}

		@include tablet {
			margin: 0 auto;
		}

		@include phone {
			margin-top: 45px;
			padding-top: 26px;
			padding-right: 17px;
			padding-left: 17px;
			padding-top: 42px;
		}
	}
}
