.error-page {
	&__container {
		@extend %container;
	}

	&__body {
		padding: 276px 0 161px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;

		@include tablet {
			padding: 249px 0 138px;
		}

		@include phone {
			padding-top: 226px;
		}
	}

	&__svg {
		position: absolute;
		transform: translateY(-70px);
		width: 761px;
		height: 345px;

		@include tablet {
			width: 100%;
		}

		svg {
			width: 100%;
			height: 100%;
		}
	}

	&__number {
		font-weight: 500;
		font-size: 190.179px;
		line-height: 232px;
		text-align: center;
		text-transform: uppercase;
		color: $color_primary;
		padding-bottom: 40px;

		@include tablet {
			font-size: 124px;
			line-height: 151px;
			padding-bottom: 69px;
		}
	}
}
