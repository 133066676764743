.header-nav {
	$that: &;

	&__list {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__item {
		margin-left: 34px;
		flex: none;

		&:hover {
			span {
				border-bottom: 1px solid rgba(255, 255, 255, 0.46);
			}
		}

		&:first-child {
			margin-left: 0;
		}

		&._popover {
			position: relative;
		}
	}

	&__link {
		display: inline-flex;
		justify-content: flex-start;
		align-items: center;
		color: $white;
		opacity: 0.8;
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		text-transform: uppercase;
		transition: opacity 0.15s ease;

		&:hover,
		#{$that}__item._popover:hover & {
			color: $white;
			opacity: 1;
		}

		#{$that}__item._popover & {
			position: relative;

			&:before {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				width: 300%;
				height: 24px;
				transform: translateX(-50%);
				background: #000;
				opacity: 0;
				display: none;
			}

			&:hover:before {
				display: block;
			}
		}

		svg {
			width: 11px;
			height: 9px;
			margin-left: 10px;
		}
	}

	&__popover {
		position: absolute;
		padding-top: 33px;
		width: 505px;
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.6s cubic-bezier(0.25, 1, 0.5, 1);

		// opacity: 1;
		// pointer-events: all;

		.popover {
			padding: 0;
			transform: translateY(-8px);
			transition: transform 0.6s cubic-bezier(0.25, 1, 0.5, 1);
			background: transparent;
			height: 199px;
		}

		#{$that}__item._popover:hover & {
			opacity: 1;
			pointer-events: all;

			.popover {
				transform: translateY(0);
			}
		}
	}
}
