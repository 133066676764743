.hero {
	$that: &;

	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	overflow: hidden;
	//min-height: calc(1066 / 1920 * 100vw);
	min-height: 100vh;
	padding: 112px 0;
	color: $white;

	@include tablet {
		//min-height: 1066px;
		padding: 84px 0;
		text-align: center;
	}
	@include phone {
		//min-height: 812px;
		flex-direction: column;
		justify-content: center;
		padding: 74px 0;
	}

	&__back {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			min-height: 299px;
			background: linear-gradient(
					180deg,
					rgba(11, 14, 26, 0.48) 0.91%,
					rgba(11, 14, 26, 0) 40.16%
				),
				linear-gradient(
					0deg,
					rgba(11, 14, 26, 0.35),
					rgba(11, 14, 26, 0.35)
				);
		}
	}

	&__back-image {
		@include object-fit(cover);
		@include lazy-loaded;
		display: block;
		width: 100%;
		height: 100%;

		#{$that}__back-video + & {
			display: none;

			@include tablet {
				display: block;
			}
		}
	}

	&__back-video {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: auto;
		height: auto;
		min-width: 100%;
		min-height: 100%;
		opacity: 0;
		transition: opacity 0.8s ease-out;

		@include tablet {
			display: none;
		}

		&._is-loaded {
			opacity: 1;
		}
	}

	&__breadcrumbs {
		@extend %container;
		position: absolute;
		left: 0;
		top: 114px;
		z-index: 20;
		margin-top: 23px;

		@include tablet {
			top: 110px;
		}
		@include phone {
			top: 87px;
			margin-top: 14px;
		}
	}

	&__inner {
		@extend %container;
		position: relative;
		z-index: 2;

		@include phone {
			flex: 1 1 0%;
			display: flex;
			justify-content: center;
			align-items: flex-end;
			padding-top: 64px;
			padding-bottom: calc(112 / 812 * 100vh);
		}
	}

	&__block {
		position: relative;
		max-width: 906px;
	}

	&__title {
		font-weight: 500;
		font-size: 82px;
		line-height: 88px;
		text-transform: uppercase;
		margin: 0;
		padding-bottom: 2px;
		display: inline;
		border-bottom: 2px solid rgba($white, 0.55);

		@include phone {
			font-size: 42px;
			line-height: 51px;
			padding-bottom: 0;
			border-bottom: none;
		}
		@include phone-s {
			font-size: 34px;
			line-height: 40px;
		}

		._secondary & {
			font-size: 72px;
			line-height: 78px;
			padding-bottom: 0px;
			border-bottom: none;

			@include tablet {
				line-height: 76px;
			}

			@include phone {
				font-size: 28px;
				line-height: 40px;
			}
		}
	}

	&__desc {
		font-weight: 600;
		font-size: 24px;
		line-height: 29px;
		text-transform: uppercase;
		padding-top: 36px;

		@include phone {
			font-size: 16px;
			line-height: 28px;
			padding-top: 20px;
		}

		._secondary & {
			line-height: 38px;
			padding-top: 27px;

			@include tablet {
				padding-top: 45px;
			}

			@include phone {
				padding-top: 20px;
				line-height: 28px;
			}

			span {
				border-bottom: 1px solid;
			}
		}
	}

	&__bottom {
		color: $white;
		position: absolute;
		right: 33px;
		bottom: 33px;
		z-index: 20;
		width: 204px;
		height: 204px;
		cursor: pointer;

		@include flex-center();

		@include tablet {
			bottom: 89px;
		}
		@include phone {
			position: relative;
			//bottom: 98px;
			//right: 50%;
			//transform: translateX(50%);
			right: 0;
			bottom: 0;
			width: 120px;
			height: 120px;
			margin-top: auto;
		}

		&:hover {
			#{$that}__circle {
				transform: scale(0.86);

				svg {
					opacity: 1;
				}
			}
			#{$that}__arrow {
				transform: translateY(8px);
			}
		}
	}

	&__circle {
		position: absolute;
		transform: scale(1);
		transition: transform 1s cubic-bezier(0.25, 0.1, 0, 1);

		svg {
			transform-origin: center center;
			animation: textCircleRotateAnimation 28s linear;
			width: 204px;
			height: 204px;
			opacity: 0.43;
			transition: opacity 1s cubic-bezier(0.25, 0.1, 0, 1);

			._secondary & {
				opacity: 1;
			}

			@include phone {
				width: 120px;
				height: 120px;
			}
		}
	}

	&__arrow {
		transform: translateY(0px);
		transition: transform 0.8s cubic-bezier(0.35, 1, 0.5, 2);

		svg {
			width: 16px;
			height: 14px;
		}
	}

	&__about {
		display: inline-block;
		margin-top: 65px;

		@include phone {
			margin-top: 46px;
		}
	}
}
