.ui-page {
	$that: &;

	@extend %container;
	overflow: hidden;

	&__section {
		padding: 48px 0;
	}

	&__title {
		& + & {
			margin-top: 40px;
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin-top: 32px;
	}

	&__item {
		padding: 40px;

		&._bg_dark {
			background: #202328;
		}
	}

	&__item-buttons {
		padding-top: 32px;

		&._bg_dark {
			background: #202328;
			padding: 24px;
			margin-top: 32px;
		}
	}

	&__field {
		max-width: 425px;
		margin: 32px auto 0;

		&._bg_dark {
			background: #202328;
			padding: 24px;
			margin-top: 32px;
		}
	}

	&__tabs {
		padding-top: 36px;
	}

	&__logo {
		width: 193px;
	}
}
