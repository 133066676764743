.management-modal {
	$that: &;

	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	max-width: 759px;
	min-height: 100%;
	margin: auto;
	padding: 0px;
	color: $color_body;
	background: $white;

	// @include tablet {
	// 	margin: auto;
	// }

	@include tablet {
		left: 0;
		max-width: none;
		min-width: auto;
	}

	& + & {
		z-index: 10;
	}

	&__close {
		position: sticky;
		top: 0px;
		width: 71px;
		height: 0;
		z-index: 20;
		margin-left: -71px;

		transform: scale(1);
		transition: transform 0.1s ease-out;

		.close-modal {
			position: relative;
			top: -80px;
		}

		@include tablet {
			margin-left: 0;
			top: 0;
		}
	}

	&__body {
		padding: 84px 60px 60px;

		@include phone {
			padding: 64px 30px 40px;
		}
		@include phone-s {
			padding: 64px 16px 40px;
		}
	}

	&__picture {
		width: 422px;
		margin: 0 auto;

		@include phone {
			width: 100%;
			max-width: 380px;
		}

		img {
			width: 100%;
			height: 100%;

			@include object-fit(cover, center);
			@include lazy-loaded;
		}
	}

	&__main {
		padding-top: 68px;

		@include phone {
			padding-top: 32px;
		}
	}

	&__desc {
		padding-top: 46px;
		border-top: 1px solid #e5e5e5;

		@include phone {
			padding-top: 32px;
		}
	}

	&__name {
		font-weight: 500;
		font-size: 24px;
		line-height: 41px;
		text-transform: uppercase;
		padding-bottom: 4px;

		@include phone {
			font-size: 16px;
			line-height: 20px;
			padding-bottom: 13px;
		}
	}

	&__position {
		font-weight: 600;
		font-size: 16px;
		line-height: 26px;
		color: $color_primary;
		padding-bottom: 29px;

		@include phone {
			font-size: 14px;
			line-height: 20px;
		}
	}

	&__text {
		font-weight: 500;
		font-size: 16px;
		line-height: 32px;

		@include phone {
			font-size: 12px;
			line-height: 18px;
		}

		p + p {
			padding-top: 22px;

			@include phone {
				padding-top: 16px;
			}
		}
	}
}
