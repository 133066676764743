.healthy-page {
	$that: &;

	&__wrap {
		position: relative;
		z-index: 20;
		background: $white;
	}

	&__container {
		@extend %container;
		position: relative;
	}

	&__body {
		position: relative;
		z-index: 20;
	}

	&__main {
		padding: 172px 0 0;
		position: relative;

		@include tablet {
			padding: 109px 0 0;
		}
		@include phone {
			padding: 47px 0 0;
		}
	}

	&__main-text {
		display: flex;
		max-width: 1150px;
		min-height: 500px;
		margin: 0 auto;
		padding-bottom: 104px;

		@include tablet {
			padding-bottom: 63px;
			display: block;
			min-height: auto;
		}
		@include phone {
			padding-bottom: 59px;
		}
	}

	&__main-title {
		padding-right: 54px;
		font-weight: 500;
		font-size: 36px;
		line-height: 64px;
		text-align: right;
		text-decoration: underline;
		text-decoration-skip-ink: none;
		text-transform: uppercase;
		width: 52%;
		position: relative;

		@include tablet {
			width: 100%;
			text-align: center;
			padding: 0;
		}
		@include phone {
			font-size: 24px;
			line-height: 36px;
		}
	}

	&__main-desc {
		padding-left: 54px;
		padding-right: 54px;
		font-weight: 500;
		font-size: 18px;
		line-height: 38px;
		width: 48%;
		transform: translateY(12px);

		p + p {
			margin-top: 28px;

			@include phone {
				margin-top: 20px;
			}
		}

		@include tablet {
			width: 100%;
			text-align: center;
			padding: 26px 0 0;
		}
		@include phone {
			font-size: 14px;
			line-height: 28px;
		}
	}

	&__main-picture {
		max-width: 874px;
		margin: 0 auto;
		position: relative;
		padding: 0 110px;

		@include phone {
			height: 480px;
			padding: 0;
		}

		img {
			width: 100%;
			height: 100%;

			@include object-fit(cover, center);
			@include lazy-loaded;
		}
	}

	&__main-picture-wrap {
		overflow: hidden;

		@include phone {
			height: 480px;
		}
	}

	&__main-baikal {
		position: absolute;
		right: 54px;
		bottom: -12px;
		width: 239px;
		height: 239px;
		border-radius: 100%;

		@include flex-center();
		flex-direction: column;
		background: $color_baikal;

		@include tablet {
			display: none;
		}

		@include phone {
			bottom: -63px;
			right: 50%;
			transform: translateX(50%);
			width: 149px;
			height: 149px;
		}
	}

	&__main-baikal-mobile {
		position: absolute;
		right: 0;
		bottom: -96px;
		width: 239px;
		height: 239px;
		border-radius: 100%;

		@include flex-center();
		flex-direction: column;
		background: $color_baikal;

		@media (min-width: 1024px) {
			display: none;
		}

		@include phone {
			bottom: -63px;
			right: 50%;
			transform: translateX(50%);
			width: 149px;
			height: 149px;

			svg {
				width: 108px;
			}
		}
	}

	&__arrow-down {
		position: absolute;
		left: 10%;
		bottom: -85px;

		@include laptop {
			left: 0;
		}
		@include tablet {
			bottom: -170px;
		}
		@include phone {
			bottom: -65px;
			left: 15px;

			svg {
				width: 54px;
				height: 101px;
			}
		}
	}

	&__baikalsea {
		padding: 375px 0 222px;
		position: relative;
		text-align: center;

		@include tablet {
			padding: 285px 0 92px;
		}
		@include phone {
			padding: 110px 0 60px;
		}
	}

	&__baikalsea-title {
		font-weight: 500;
		font-size: 72px;
		line-height: 88px;
		text-align: center;
		display: inline-block;
		border-bottom: 2px solid $color_baikal;
		padding-bottom: 2px;

		@include tablet {
			font-size: 48px;
			line-height: 59px;
		}

		@include phone {
			display: none;
			font-size: 42px;
			line-height: 51px;
			border-bottom: none;
		}
	}

	&__baikalsea-back {
		font-weight: normal;
		font-size: 161px;
		line-height: 196px;
		text-align: center;
		color: rgba(21, 22, 23, 0.02);
		white-space: nowrap;

		position: absolute;
		top: 215px;
		left: 50%;
		transform: translateX(-50%);

		@include tablet {
			display: none;
			top: 180px;
			font-size: 16vw;
		}
		@include phone {
			top: 10px;
			font-size: 57px;
		}

		&._mobile {
			display: none;

			@include tablet {
				display: block;
			}
		}
	}

	&__baikalsea-text {
		font-weight: 500;
		font-size: 18px;
		line-height: 38px;
		padding-top: 42px;
		max-width: 832px;
		margin: 0 auto;

		@include tablet {
			padding-top: 32px;
		}

		@include phone {
			padding-top: 19px;
			font-size: 14px;
			line-height: 28px;
		}
	}

	&__baikalsea-link {
		margin-top: 32px;
		font-size: 16px;

		a:hover {
			color: $color_baikal;
		}

		@include phone {
			margin-top: 24px;
			font-size: 12px;
		}
	}

	&__picture {
		position: relative;
		height: 852px;
		@include full-width();

		@include laptop {
			height: 577px;
		}

		@include phone {
			height: 289px;
		}

		img {
			width: 100%;
			height: 100%;

			@include object-fit(cover, center);
			@include lazy-loaded;
		}

		svg {
			position: absolute;
			z-index: 2;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			@include tablet {
				width: 500px;
			}

			@include phone {
				width: calc(500 / 639 * 100vw);
			}
		}
	}

	&__block {
		padding-top: 168px;

		@include tablet {
			padding-top: 116px;
		}
		@include phone {
			padding-top: 60px;
		}
	}

	&__block-title {
		padding-bottom: 108px;

		@include tablet {
			padding-bottom: 42px;
		}

		.title-section__title {
			margin-left: 0;
		}
	}

	&__block-subtitle {
		color: #151617;
		font-weight: 500;
		font-size: 38px;
		line-height: 64px;
		text-decoration-line: underline;
		text-transform: uppercase;
		margin-bottom: 34px;

		&--right {
			text-align: right;
		}

		@include tablet {
			display: none;
		}
	}

	&__block-subtitle-mobile {
		color: #151617;
		font-weight: 500;
		font-size: 38px;
		line-height: 64px;
		text-decoration-line: underline;
		text-transform: uppercase;
		margin-bottom: 34px;
		text-align: center;

		@media (min-width: 1024px) {
			display: none;
		}

		@include phone {
			font-size: 22px;
			line-height: 36px;
		}
	}

	&__block-body {
		display: flex;
		max-width: 1470px;
		margin: 0 auto;

		@include tablet {
			display: block;
		}
	}

	&__block-picture {
		width: 50%;
		height: 710px;
		max-width: 605px;
		overflow: hidden;

		@include tablet {
			width: 100%;
			max-width: none;
			height: 498px;
		}
		@include phone {
			height: auto;
		}

		img {
			width: 100%;
			height: 100%;

			@include object-fit(cover, left);
			@include lazy-loaded;
		}
	}

	&__block-desc {
		position: relative;
		width: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-left: 13.33%;

		&--right {
			padding-right: 13.33%;
			padding-left: 0;

			@include tablet {
				padding-right: 0;
			}
		}

		@include tablet {
			width: 100%;
			flex-direction: row-reverse;
			padding-left: 0;
		}
		@media (max-width: 767px) {
			display: block;
			padding-top: 54px;
		}
	}

	&__block-arrow-down {
		position: absolute;
		right: -150px;
		top: 91px;

		@include desktop {
			right: unset;
			top: 114px;
			left: 45px;
			transform: scale(-1, 1);
		}

		@include tablet {
			right: unset;
			top: unset;
			bottom: -72px;
			left: 200px;
			transform: scale(0.7) rotate(146deg);
		}

		@media (max-width: 768px) {
			right: 50px;
			top: unset;
			bottom: 95px;
			left: unset;
			transform: scale(0.7) rotate(45deg);
		}

		@include phone {
			right: -40px;
			bottom: 130px;
			transform: scale(0.5) rotate(10deg);
		}
	}

	&__block-graphics {
		position: relative;
		display: flex;
		justify-content: center;
		margin-top: 96px;

		& svg {
			transition: transform ease-in-out 300ms;
		}

		&:hover {
			& svg {
				transform: scale(1.08);
			}
		}

		@include tablet {
			margin-top: -150px;
		}

		@media (max-width: 767px) {
			margin-top: 0;
		}
	}

	&__block-text {
		font-weight: 500;
		font-size: 18px;
		line-height: 38px;
		text-align: center;
		max-width: 517px;

		p + p {
			margin-top: 28px;

			@include phone {
				margin-top: 20px;
			}
		}

		&--left {
			text-align: left;

			@include tablet {
				text-align: center;
			}
		}

		&--right {
			text-align: right;

			@include tablet {
				text-align: center;
			}
		}

		@include tablet {
			width: 50%;
			margin: 0;
			padding-top: 89px;
			padding-right: 48px;
		}

		@media (max-width: 767px) {
			width: 100%;
			padding-right: 0;
			padding-top: 20px;
			max-width: none;
		}

		@include phone {
			font-size: 14px;
			line-height: 28px;
		}
	}

	&__lab {
		padding-top: 48px;

		@include tablet {
			padding-top: 116px;
		}

		@include phone {
			padding-top: 60px;
		}
	}

	&__eco-text,
	&__lab-text {
		@include tablet {
			text-align: center;
			width: 100%;
			font-weight: 500;
			font-size: 18px;
			line-height: 38px;
			padding: 42px 0 0;
			max-width: 832px;
			margin: 0 auto;
		}

		@include phone {
			padding-top: 0px;
			font-size: 14px;
			line-height: 28px;
		}
	}

	&__greenhouses {
		@include tablet {
			padding-bottom: 100px;
		}

		@include phone {
			padding-bottom: 60px;
		}
	}

	&__greenhouses-text {
		@include phone {
			padding-top: 0;
		}
	}

	&__lab-body {
		@include tablet {
			display: flex !important;
			flex-direction: column-reverse;
		}
	}

	&__chart {
		background-color: #f9f9f9;

		&--greenhouses {
			margin-top: -140px;
			padding-top: 285px;
			padding-bottom: 188px;

			@include laptop {
				margin-left: -36px;
				margin-right: -36px;
				padding-right: 36px;
				padding-left: 36px;
			}

			@include tablet {
				margin-top: 0;
				padding-top: 100px;
				padding-bottom: 145px;
			}
			@include phone {
				padding-top: 60px;
				padding-bottom: 60px;
			}
		}

		&--baikalsea {
			margin-right: -36px;
			margin-left: -36px;
			padding: 100px 36px;

			@include phone {
				padding-top: 50px;
				padding-bottom: 50px;
			}
		}

		&-head {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: start;
			margin-bottom: 200px;

			@include laptop {
				margin-bottom: 136px;
			}

			@include phone {
				margin-bottom: 78px;
			}
		}

		&-title {
			margin-bottom: 29px;
			font-weight: 500;
			font-size: 48px;
			line-height: 59px;
			text-transform: uppercase;
			text-align: center;

			@include phone {
				font-size: 24px;
				line-height: 29px;
				margin-bottom: 22px;
			}

			span {
				border-bottom: 2px solid rgba(0, 0, 0, 0.15);
			}
		}

		&-subtitle {
			position: relative;
		}

		&-year {
			position: relative;
			font-style: normal;
			font-weight: normal;
			font-size: 57px;
			line-height: 67px;
			color: #404142;
			text-align: center;
			z-index: 2;

			@include phone {
				font-size: 32px;
				line-height: 37px;
			}

			span {
				font-family: Montserrat;
				font-style: normal;
				font-weight: 500;
				font-size: 22px;
				line-height: 26px;
				color: #484848;

				@include phone {
					font-size: 20px;
					line-height: 24px;
				}
			}
		}

		&-icon {
			position: absolute;
			top: 65%;
			left: 50%;
			transform: translateX(-50%);
			display: flex;
			align-items: center;
			justify-content: center;
			width: 174px;
			height: 174px;
			border: 1px solid #d4d4d4;
			border-radius: 50%;

			@include phone {
				width: 96px;
				height: 96px;
			}

			img {
				display: block;

				@include phone {
					width: 30px;
				}
			}
		}

		&-wrapper {
			position: relative;
			width: 100%;
			max-width: 1510px;
			margin: 0 auto;
			margin-top: -222px;
			padding: 52px 72px 53px 75px;
			background-color: $white;
			border: 1px solid rgba(0, 0, 0, 0.1);
			border-radius: 2px;
			overflow-x: scroll;
			overflow-y: hidden;
			z-index: 1;

			&::-webkit-scrollbar {
				height: 4px; //для горизонтального скролла
			}
			&::-webkit-scrollbar-track {
				background: transparent;
				border-radius: 10px;
			}
			&::-webkit-scrollbar-thumb {
				bottom: 5px;
				background-color: $color_primary;
				border: none;
				border-radius: 10px;
			}

			@include tablet {
				margin-top: 0;
				padding-right: 20px;
				padding-left: 20px;
			}

			@include phone {
				padding-top: 26px;
				padding-right: 0;
				padding-left: 17px;
				padding-top: 42px;
			}
		}
	}
}
