.news {
	$that: &;

	padding-bottom: 130px;

	@include tablet {
		padding-bottom: 94px;
	}
	@include phone {
		padding-bottom: 72px;
	}

	&__inner {
		@extend %container;
	}

	&__body {
		padding-top: 84px;
		position: relative;

		@include tablet {
			padding-top: 61px;
		}
		@include phone {
			padding-top: 26px;
		}
	}
}
