.title-section {
	$that: &;

	@include flex-center();

	&__title {
		font-weight: 500;
		font-size: 48px;
		line-height: 59px;
		text-align: center;
		text-transform: uppercase;
		position: relative;
		@include flex-center();
		padding-bottom: 20px;

		margin-left: 70px;

		@include tablet {
			font-size: 40px;
			line-height: 46px;
		}

		@include phone {
			font-size: 24px;
			line-height: 29px;
			padding-bottom: 0;
			flex-direction: column;
			margin-left: 0;
		}
	}

	&__text {
		position: relative;

		@include phone {
			padding-bottom: 14px;
		}

		&::after {
			content: '';
			position: absolute;
			bottom: -19px;
			left: 50%;
			transform: translateX(-50%);
			width: 118px;
			height: 2px;
			background: $color_primary;

			@include phone {
				bottom: 0px;
			}
		}
	}

	&__link {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	&__arrow {
		margin-left: 26px;

		@include phone {
			margin-left: 0;
			padding-top: 16px;
		}
	}
}
