.chart {
	$that: &;

	display: flex;

	@include laptop {
		display: block;
	}

	&__title {
		width: 100%;
		flex-shrink: 1;
		margin-right: auto;
		text-align: center;
		border-right: 1px solid rgba(74, 77, 90, 0.1);
		padding-top: 100px;
		padding-bottom: 88px;

		@include laptop {
			padding: 0 !important;
			margin: 0 auto;
			margin-bottom: 26px;
			border: none;
		}

		@include tablet {
			max-width: 412px !important;
		}

		@include phone {
			margin-bottom: 60px;
		}

		&-text {
			font-style: normal;
			font-weight: normal;
			font-size: 68px;
			line-height: 80px;
			color: $color_primary;

			@include phone {
				font-size: 32px;
				line-height: 37px;
			}

			span {
				font-family: Montserrat;
				font-style: normal;
				font-weight: 500;
				font-size: 26px;
				line-height: 31px;
				color: #484848;

				@include phone {
					font-size: 14px;
					line-height: 17px;
				}
			}
		}

		&-icons {
			display: flex;
			align-items: center;
			margin-top: 49px;
		}

		@include phone {
			display: none;
		}

		&-icon {
			width: 118px;
			height: 118px;
			border: 1px solid #edeeef;
			border-radius: 50%;
			svg {
				width: 100%;
				height: 100%;
			}
		}
	}

	&__drawing {
		width: 100%;

		&-title {
			span {
				background-color: $color_primary;
			}

			&--blue {
				span {
					background-color: #3a7698;
				}
			}
			&--mint {
				span {
					background-color: #3a9887;
				}
			}
			&--viridian {
				span {
					background-color: #3a9865;
				}
			}
		}
	}

	&__legend {
		display: flex;
		align-items: center;

		&-item {
			position: relative;
			padding-left: 60px;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 18px;
			color: #414141;

			@include phone {
				font-size: 12px;
				padding-left: 40px;
			}

			&:before {
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				background-color: $color_primary;
				width: 44px;
				height: 3px;

				@include phone {
					width: 31px;
				}
			}

			&--blue {
				&:before {
					background-color: #3a7698;
				}
			}

			&--mint {
				&:before {
					background-color: #3a9887;
				}
			}
		}
	}

	&__wrapper {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			width: 5px;
			height: 5px;
			background-color: $color_primary;
		}
	}

	&__axis {
		position: absolute;
		display: flex;
		align-items: center;

		&--y {
			top: 0;
			width: 0;
			flex-direction: column;

			.chart__axis-arrow {
				margin-bottom: 3px;
			}

			.chart__axis-line {
				width: 1px;
				height: 100%;
			}
		}

		&--x {
			height: 0;
			right: 0;

			.chart__axis-arrow {
				margin-left: 3px;
			}

			.chart__axis-line {
				height: 1px;
				width: 100%;
			}
		}

		&-line {
			background-color: rgba(74, 77, 90, 0.1);
		}

		&-text {
			margin-bottom: 10px;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 15px;
			color: #989898;
		}
	}

	&__list {
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		&--y {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
		}

		&--line {
			width: 100%;
			padding-left: 21px;
		}

		&-title {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			height: 100%;
			display: flex;
			flex-shrink: 0;
			align-items: center;
			justify-content: center;
			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
			color: rgba(72, 72, 72, 0.56);
			width: 0;

			@include phone {
				font-size: 12px;
				line-height: 15px;
			}

			span {
				transform: rotate(-90deg);
			}
		}

		&-item {
			position: relative;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			color: $white;
			z-index: 1;

			@include phone {
				font-size: 12px;
			}

			&--y {
				display: flex;
				align-items: center;
				justify-content: end;
				color: #414141;

				span {
					position: relative;
					width: 13px;
					height: 1px;
					margin-left: 15px;
					background-color: rgba(72, 72, 72, 0.29);

					@include phone {
						margin-left: 10px;
					}

					&:before {
						content: '';
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 50%;
						background-color: $color_primary;
					}
				}
			}

			&--line {
				width: 100%;

				&:before {
					content: '';
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					height: 1px;
					width: 100%;
					background-color: rgba(74, 77, 90, 0.1);
					z-index: -1;
				}
			}

			&--zero {
				position: absolute;
				right: 0;
				bottom: 48px;
				height: 3px !important;
			}
		}
	}

	&__columns {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		display: flex;
		justify-content: space-between;

		&-item {
			display: flex;
			flex-direction: column;
			justify-content: end;
			width: 100%;
		}

		&-wrapper {
			display: flex;
			align-items: flex-end;
			justify-content: space-evenly;
			height: 100%;
		}

		&-block {
			height: 12px;
			margin-top: 12px;
			margin-bottom: 6px;
			border: 1px solid #414141;
			border-top-color: transparent;
		}

		&-year {
			display: block;
			height: 23px;
			text-align: center;
			font-weight: 500;
			font-size: 16px;
			color: #414141;

			@include phone {
				font-size: 12px;
			}
		}
	}

	&__column {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		background-color: $color_primary;
		z-index: 2;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 0;
			height: 0;
			border-top: 10px solid $white;
			border-left: 10px solid transparent;
		}
		&:after {
			content: '';
			position: absolute;
			left: 50%;
			bottom: -12px;
			transform: translateY(-50%);
			width: 1px;
			height: 6px;
			background-color: $color_primary;
		}

		&--blue {
			background-color: #3a7698;

			&:after {
				background-color: #3a7698;
			}
		}
		&--mint {
			background-color: #3a9887;

			&:after {
				background-color: #3a9887;
			}
		}
		&--viridian {
			background-color: #3a9865;

			&:after {
				background-color: #3a9865;
			}
		}

		&-text {
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 23px;
			color: $white;
			text-align: center;

			@include phone {
				font-size: 12px;
			}

			span {
				display: block;
				font-size: 12px;
				color: rgba(255, 255, 255, 0.5);
			}
		}
	}

	.investors__chart & {
		align-items: center;

		&__title {
			max-width: 514px;
			padding-right: 100px;

			&-icons {
				justify-content: space-between;
			}
		}

		&__drawing {
			max-width: 720px;
			flex-shrink: 0;

			@include desktop {
				max-width: 50%;
			}

			@include laptop {
				max-width: initial;
			}

			@include tablet {
				width: 722px;
				padding-right: 20px;
			}

			@include phone {
				width: 500px;
			}
		}

		&__wrapper {
			padding-left: 75px;
			padding-bottom: 75px;

			&:before {
				left: 75px;
				bottom: 75px;
			}
		}

		&__axis {
			&--y {
				bottom: 75px;
			}
			&--x {
				left: 75px;
			}
		}

		&__list {
			padding-top: 88px;
			padding-bottom: 66px;

			&--x {
				position: absolute;
				right: 0;
				bottom: 0;
				left: 75px;
				align-items: flex-start;
				justify-content: space-between;
				flex-direction: row;
				padding: 0;
				padding-right: 25px;
				padding-left: 78px;

				@include phone {
					left: 50px;
					right: 40px;
				}
			}

			&-item {
				height: 42px;

				@include phone {
					height: 28px;
				}

				&:not(:last-child) {
					margin-bottom: 25px;
				}

				&--x {
					position: relative;
					padding-top: 20px;
					font-size: 14px;
					line-height: 17px;
					color: #484848;
					text-align: center;

					@include phone {
						font-size: 12px;
						line-height: 15px;
					}

					span {
						display: block;
						margin-top: 15px;
						color: #989898;
					}

					&:before {
						content: '';
						position: absolute;
						top: 0;
						left: 50%;
						transform: translateX(-50%);
						width: 1px;
						height: 5px;
						background-color: $color_primary;
					}
				}
			}
		}

		&__row {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			max-width: 82%;
			height: 100%;
			background-color: $color_primary;

			@include phone {
				max-width: 72%;
			}

			&--small {
				max-width: 232px;

				@include desktop {
					max-width: 36%;
				}

				@include phone {
					max-width: 32%;
				}
			}

			&:before {
				content: '';
				position: absolute;
				bottom: 0;
				right: 0;
				width: 0;
				height: 0;
				border-bottom: 10px solid $white;
				border-left: 10px solid transparent;
			}
		}
	}

	.hevel-page__chart & {
		justify-content: space-between;

		&__legend {
			justify-content: center;
			margin-bottom: 100px;

			@include phone {
				margin-bottom: 27px;
			}

			&-item {
				&:not(:first-child) {
					margin-left: 52px;

					@include phone {
						margin-left: 25px;
					}
				}
			}
		}

		&__drawing {
			max-width: 49%;
			padding: 75px 99px 100px 56px;
			background-color: $white;
			border: 1px solid rgba(0, 0, 0, 0.1);
			border-radius: 2px;
			overflow-y: scroll;

			&::-webkit-scrollbar {
				height: 4px; //для горизонтального скролла
			}
			&::-webkit-scrollbar-track {
				background: transparent;
				border-radius: 10px;
			}
			&::-webkit-scrollbar-thumb {
				bottom: 5px;
				background-color: $color_primary;
				border: none;
				border-radius: 10px;
			}

			@include desktop {
				padding-right: 20px;
				padding-left: 20px;
			}

			@include laptop {
				max-width: 100%;

				&:first-child {
					margin-bottom: 56px;
				}
			}

			@include phone {
				padding: 30px;
			}

			&-title {
				margin-bottom: 20px;
				font-style: normal;
				font-weight: 500;
				font-size: 24px;
				line-height: 51px;
				text-align: center;
				text-transform: uppercase;
				color: $white;

				@include phone {
					font-size: 16px;
				}

				span {
					padding: 15px 20px;
				}
			}
		}

		&__wrapper {
			padding-left: 100px;
			padding-bottom: 48px;

			@include laptop {
				width: 100%;
				max-width: 736px;
				margin: 0 auto;
			}

			@include tablet {
				max-width: initial;
				width: 746px;
			}

			@include phone {
				padding-left: 70px;
				width: 570px;
			}

			&:before {
				left: 100px;
				bottom: 48px;

				@include phone {
					left: 70px;
				}
			}
		}

		&__list {
			padding-top: 35px;
			padding-bottom: 60px;

			&--y {
				left: 20px;

				@include phone {
					left: 10px;
				}
			}

			&-title {
				left: -20px;
			}

			&-item {
				height: 17px;

				&:not(:last-child) {
					margin-bottom: 42px;
				}
			}
		}

		&__axis {
			&--y {
				bottom: 48px;
			}
			&--x {
				left: 100px;

				@include phone {
					left: 70px;
				}
			}
		}

		&__columns {
			left: 100px;

			@include phone {
				left: 70px;
			}

			&-item {
				max-width: 300px;

				@include phone {
					max-width: 232px;
				}
			}
		}

		&__column {
			max-width: 83px;

			@include phone {
				max-width: 72px;
			}
		}
	}

	.ecotourism-page__chart & {
		align-items: center;

		@include desktop {
			display: block;
		}

		&__title {
			max-width: 380px;
			padding-right: 70px;

			@include desktop {
				padding: 0;
				margin: 0 auto;
				margin-bottom: 26px;
				border: none;
			}

			@include tablet {
				max-width: 412px;
			}

			@include phone {
				margin-bottom: 60px;
			}

			&-icons {
				justify-content: center;

				@include desktop {
					margin-top: 10px;
				}
			}
		}

		&__legend {
			justify-content: end;
			margin-bottom: 29px;

			@include tablet {
				justify-content: center;
			}

			@include phone {
				justify-content: start;
			}

			// @include phone {
			// 	margin-bottom: 27px;
			// }

			&-item {
				&:not(:first-child) {
					margin-left: 38px;

					@include phone {
						margin-left: 25px;
					}
				}
			}
		}

		&__drawing {
			max-width: 920px;
			flex-shrink: 0;

			@include desktop {
				margin: 0 auto;
			}

			@include laptop {
				// max-width: initial;
			}

			@include tablet {
				width: 810px;
				padding-right: 20px;
			}

			@include phone {
				width: 660px;
			}
		}

		&__wrapper {
			padding-left: 180px;
			padding-bottom: 48px;

			@include phone {
				padding-left: 115px;
			}

			&:before {
				left: 180px;
				bottom: 48px;

				@include phone {
					left: 115px;
				}
			}
		}

		&__list {
			padding-top: 35px;
			padding-bottom: 60px;

			&--y {
				left: 60px;

				@include phone {
					left: 30px;
				}
			}

			&-title {
				left: -30px;
			}

			&-item {
				height: 17px;

				&:not(:last-child) {
					margin-bottom: 42px;
				}
			}
		}

		&__axis {
			&--y {
				bottom: 48px;
			}
			&--x {
				left: 180px;

				@include phone {
					left: 115px;
				}
			}
		}

		&__columns {
			left: 180px;

			@include phone {
				left: 115px;
			}

			&-item {
				max-width: 360px;

				@include laptop {
					max-width: 300px;
				}

				@include phone {
					max-width: 232px;
				}
			}
		}

		&__column {
			max-width: 92px;

			@include laptop {
				max-width: 85px;
			}

			@include phone {
				max-width: 64px;
			}
		}
	}

	.healthy-page__chart--greenhouses & {
		justify-content: space-between;
		flex-wrap: wrap;
		margin-left: -25px;
		margin-right: 25px;

		@include laptop {
			margin-left: 0;
			margin-right: 0;
		}

		&__drawing {
			position: relative;
			max-width: 49%;
			padding: 75px 50px 50px 50px;
			background-color: $white;
			border: 1px solid rgba(0, 0, 0, 0.1);
			border-radius: 2px;
			overflow-y: scroll;
			z-index: 2;

			&::-webkit-scrollbar {
				height: 4px; //для горизонтального скролла
			}
			&::-webkit-scrollbar-track {
				background: transparent;
				border-radius: 10px;
			}
			&::-webkit-scrollbar-thumb {
				bottom: 5px;
				background-color: $color_primary;
				border: none;
				border-radius: 10px;
			}

			&:nth-child(-n + 2) {
				margin-bottom: 56px;
			}

			@include desktop {
				padding-right: 20px;
				padding-left: 35px;
			}

			@include laptop {
				max-width: 100%;

				&:not(:last-child) {
					margin-bottom: 56px;
				}
			}

			@include phone {
				padding: 30px;
			}

			&-title {
				margin-bottom: 45px;
				font-style: normal;
				font-weight: 500;
				font-size: 24px;
				line-height: 51px;
				text-align: center;
				text-transform: uppercase;
				color: $white;

				@include phone {
					font-size: 16px;
				}

				&:first-child {
					margin-bottom: 10px;

					@include phone {
						margin-bottom: 0;
					}
				}

				span {
					padding: 10px 15px;
				}
			}
		}

		&__wrapper {
			padding-left: 100px;
			padding-bottom: 48px;

			@include laptop {
				width: 100%;
				max-width: 736px;
				margin: 0 auto;
			}

			@include tablet {
				max-width: initial;
				width: 746px;
			}

			@include phone {
				width: 460px;
				padding-left: 80px;
			}

			&:before {
				left: 100px;
				bottom: 48px;

				@include phone {
					left: 80px;
				}
			}
		}

		&__list {
			padding-top: 35px;
			padding-bottom: 60px;

			&-title {
				left: -15px;

				// @include desktop {
				// 	left: -25px;
				// }
			}

			&-item {
				height: 17px;

				&--y {
					width: 90px;

					@include phone {
						width: 70px;
					}
				}

				&:not(:last-child) {
					margin-bottom: 42px;
				}
			}
		}

		&__axis {
			&--y {
				bottom: 48px;
			}
			&--x {
				left: 100px;

				@include phone {
					left: 80px;
				}
			}
		}

		&__columns {
			left: 100px;

			@include phone {
				left: 80px;
			}

			&-wrapper {
				justify-content: center;
			}

			&-item {
				max-width: 150px;

				@include desktop {
					max-width: 140px;
				}

				@include phone {
					max-width: 110px;
				}
			}
		}

		&__column {
			max-width: 90px;

			@include phone {
				max-width: 70px;
			}
		}
	}

	.healthy-page__chart--baikalsea & {
		align-items: center;
		justify-content: space-between;

		&__title {
			max-width: 380px;
			padding-right: 70px;
			margin-right: 20px;

			@include laptop {
				padding-right: 0;
				margin: 0 auto;
				margin-bottom: 40px;
			}

			@include phone {
				margin-bottom: 60px;
			}

			&-icons {
				justify-content: center;

				@include desktop {
					margin-top: 10px;
				}
			}
		}

		&__legend {
			justify-content: end;
			margin-bottom: 29px;

			@include tablet {
				justify-content: center;
			}

			// @include phone {
			// 	margin-bottom: 27px;
			// }

			&-item {
				&:not(:first-child) {
					margin-left: 38px;

					@include phone {
						margin-left: 25px;
					}
				}
			}
		}

		&__drawing {
			max-width: 857px;

			@include laptop {
				margin: 0 auto;
			}

			@include tablet {
				width: 670px;
			}

			@include phone {
				width: 520px;
				padding-right: 20px;
				padding-left: 20px;
			}
		}

		&__wrapper {
			padding-left: 120px;
			padding-bottom: 48px;

			@include phone {
				padding-left: 70px;
			}

			&:before {
				left: 120px;
				bottom: 48px;

				@include phone {
					left: 70px;
				}
			}
		}

		&__list {
			padding-top: 35px;
			padding-bottom: 60px;

			&--y {
				left: 50px;

				@include phone {
					left: 10px;
				}
			}

			&-title {
				left: -40px;

				@include phone {
					left: -20px;
				}
			}

			&-item {
				height: 17px;

				&:not(:last-child) {
					margin-bottom: 42px;
				}
			}
		}

		&__axis {
			&--y {
				bottom: 48px;
			}
			&--x {
				left: 120px;

				@include phone {
					left: 70px;
				}
			}
		}

		&__columns {
			left: 120px;

			@include phone {
				left: 70px;
			}

			&-item {
				max-width: 550px;

				@include tablet {
					max-width: 400px;
				}

				@include phone {
					max-width: 232px;
				}
			}
		}

		&__column {
			max-width: 92px;

			@include tablet {
				max-width: 85px;
			}

			@include phone {
				max-width: 64px;
			}
		}
	}
}
