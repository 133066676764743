.responsibility {
	$that: &;
	padding-bottom: 177px;
	position: relative;
	background: #f3f3f3;

	@include tablet {
		padding-bottom: 106px;
	}

	@include phone {
		padding-bottom: 56px;
	}

	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: calc(100% - 380px);
		z-index: 0;
		pointer-events: none;
		background: $white;

		@include tablet {
			height: calc(100% - 350px);
		}
	}

	&__inner {
		@extend %container;
	}

	&__body {
		padding-top: 168px;
		position: relative;

		@include tablet {
			padding-top: 137px;
		}
		@include phone {
			padding-top: 65px;
		}
	}

	&__line {
		position: absolute;
		top: 46%;
		left: 0;
		width: 1924px;
		margin: 0 -36px;
		opacity: 0.4;

		svg {
			width: 100%;
		}
	}
}
