.popover {
	$that: &;

	position: relative;
	min-width: 505px;
	padding: 30px;
	color: $color_body;
	background-color: $white;
	box-shadow: 17px 10px 38px rgba(121, 128, 134, 0.11);
	border-radius: 4px;

	&__arrow {
		position: absolute;
		// width: 12px;
		// height: 4px;
		// visibility: hidden;

		// [data-popper-placement^='top'] > #arrow {
		// 	bottom: -4px;
		// }

		// [data-popper-placement^='bottom'] & {
		// 	bottom: 100%;
		// }
		[data-popper-placement^='bottom-start'] & {
			bottom: 96%;
		}

		// [data-popper-placement^='left'] > #arrow {
		// 	right: -4px;
		// }

		// [data-popper-placement^='right'] > #arrow {
		// 	left: -4px;
		// }
	}
}
