.input-form {
	$that: &;

	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: center;

	&:before {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 1px;
		background-color: rgba($black, 0.1);
	}

	&:after {
		content: '';
		position: absolute;
		right: 100%;
		bottom: 0;
		width: 100%;
		height: 1px;
		background-color: $color_primary;
		opacity: 0.4;
		transition: opacity 0.45s 0.1s cubic-bezier(0.76, 0, 0.24, 1),
			transform 0.45s 0.1s cubic-bezier(0.76, 0, 0.24, 1);
	}

	&._focus {
		&:after {
			opacity: 1;
			transform: translateX(100%);
		}
	}

	&__label {
		position: absolute;
		top: 25px;
		color: $color_body;
		font-weight: 500;
		font-size: 10px;
		line-height: 12px;
		transform: scale3d(1.8, 1.8, 1);
		transform-origin: center center;
		pointer-events: none;
		transition: transform 0.25s ease;
		will-change: transform;

		@include phone {
			transform: scale3d(1.2, 1.2, 1);
		}

		#{$that}._fixed_label & {
			transform: scale3d(1, 1, 1) translate3d(0, -25px, 0);
			color: $color_primary;
		}
	}

	&__field {
		@include placeholder(#606367);

		position: relative;
		width: 100%;
		padding: 4px 0;
		color: $color_body;
		font-weight: 500;
		font-size: 18px;
		line-height: 38px;
		text-align: center;
		background: transparent;
		border: none;
		border-top: 4px solid transparent !important;
		transition: color 0.25s ease-in-out, border-color 0.25s ease-in-out;
		resize: none;

		@include phone {
			font-size: 12px;
		}

		&._error_field {
			@include placeholder($red);
			color: $red;
			border-color: $red;
		}
	}

	textarea.input-form__field {
		height: 106px;
	}
}
