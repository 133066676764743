.portfolio-page {
	$that: &;

	overflow: hidden;
	background: #f9f9f9;
	padding-top: 114px;
	padding-bottom: 211px;
	position: relative;

	@include tablet {
		padding-top: 110px;
		padding-bottom: 124px;
	}
	@include phone {
		padding-top: 87px;
		padding-bottom: 88px;
	}

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 60%;
		background: $white;
		pointer-events: none;

		@include tablet {
			display: none;
		}
	}

	&__lines {
		position: absolute;
		left: 0;
		top: 14%;
		width: 100%;
		padding: 0 16px;

		@include tablet {
			top: 14%;
		}
		@include phone {
			display: none;
		}

		svg {
			width: 100%;
			height: 100%;
		}
	}

	&__container {
		@extend %container;
		position: relative;
	}

	&__breadcrumbs {
		margin-top: 23px;

		@include phone {
			margin-top: 14px;
		}
	}

	&__subtitle {
		padding-top: 148px;
		font-weight: normal;
		font-size: 12px;
		line-height: 15px;
		text-align: center;
		letter-spacing: 11.5px;

		@include phone {
			display: none;
		}
	}

	&__title {
		padding-top: 17px;
		padding-bottom: 70px;
		font-weight: 500;
		font-size: 72px;
		line-height: 88px;
		text-align: center;
		text-transform: uppercase;

		@include tablet {
			font-size: 62px;
			line-height: 76px;
			padding-top: 15px;
			padding-bottom: 43px;
		}
		@media (max-width: 767px) {
			font-size: 28px;
			line-height: 34px;
		}
		@include phone {
			padding-top: 38px;
			padding-bottom: 52px;
		}
	}

	&__globe {
		width: 167px;
		height: 167px;
		margin: 0 auto;
		position: relative;
		@include flex-center();

		@include phone {
			width: 122px;
			height: 122px;
		}
	}

	&__globe-icon {
		box-shadow: 0px 10px 61px rgba(163, 184, 190, 0.25);
		border-radius: 100%;
		position: absolute;

		svg {
			width: 84px;
			height: 84px;

			@include phone {
				width: 62px;
				height: 62px;
			}
		}
	}

	&__globe-text {
		position: absolute;

		svg {
			animation: textCircleRotateAnimation 28s linear;
			width: 100%;
			height: 100%;
			color: rgba($color_body, 0.4);
		}
	}

	&__arrow-down {
		display: flex;
		justify-content: flex-end;
		padding-right: 200px;
		margin-top: -95px;

		@include tablet {
			padding-right: 14%;
			margin-top: -145px;
		}
		@include phone {
			padding-right: 6%;
			margin-top: -72px;

			svg {
				width: 80px;
				height: 149px;
			}
		}
	}

	&__body {
		position: relative;
		z-index: 20;
		max-width: 1350px;
		margin: 0 auto;

		@include laptop {
			max-width: 594px;
			padding-top: 80px;
		}

		@include phone {
			padding-top: 98px;
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 0 0 -24px;

		@include laptop {
			margin: 0;
			display: block;
		}
	}

	&__item {
		width: 50%;
		flex: 1 0 auto;
		max-width: 618px;
		padding-left: 24px;
		display: block;

		@include laptop {
			padding-left: 0;
			width: 100%;
			max-width: 594px;
			flex: none;
		}

		&:hover {
			@include laptop-hover {
				#{$that}__item-picture {
					img {
						transform: scale(1.042);
					}
				}
				#{$that}__item-title {
					color: $white;

					span {
						&::before {
							transform: scaleY(1);
						}
					}
				}
			}
		}

		& + #{$that}__item {
			padding-top: 174px;

			@include laptop {
				padding-top: 130px;
			}

			@include phone {
				padding-top: 55px;
			}
		}

		&:nth-child(2) {
			margin-top: 214px;

			@include laptop {
				margin: 0;
			}
		}
		&:nth-child(3) {
			margin-top: -426px;

			@include laptop {
				margin: 0;
			}
		}
	}

	&__item-title {
		font-weight: 500;
		font-size: 24px;
		line-height: 38px;
		text-transform: uppercase;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		transition: color 0.8s cubic-bezier(0.25, 0.1, 0, 1);

		@include phone {
			font-size: 18px;
			line-height: 30px;
		}

		span {
			padding: 5px 12px;
			margin-left: 24px;
			position: relative;

			@include phone {
				padding: 0;
				margin-left: 20px;
			}

			&::before {
				position: absolute;
				content: '';
				left: 0;
				top: 0;
				z-index: -1;
				width: 100%;
				height: 100%;
				background: $color_primary;
				transform: scaleY(0);
				transform-origin: bottom;
				transition: transform 1.2s cubic-bezier(0.25, 0.1, 0, 1);
				will-change: transform;
			}
		}
	}

	&__item-arrow {
		flex: none;
	}

	&__item-desc {
		font-weight: 500;
		font-size: 24px;
		line-height: 38px;
		color: $color_primary;
		padding-bottom: 37px;
		padding-left: 99px;
		margin-top: -6px;

		@include phone {
			font-size: 16px;
			line-height: 26px;
			padding-left: 83px;
			padding-top: 9px;
			margin-top: 0;
		}
	}

	&__item-picture {
		max-height: 662px;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;

			@include object-fit(contain, center);
			@include lazy-loaded;

			transform: scale(1);
			transition: transform 2s cubic-bezier(0.25, 0.1, 0, 1);
			will-change: transform;
		}
	}
}
