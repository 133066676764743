.responsibility-page {
	overflow: hidden;
	background: #f9f9f9;
	padding-top: 114px;
	padding-bottom: 182px;
	position: relative;

	@include tablet {
		padding-top: 110px;
		padding-bottom: 135px;
	}
	@include phone {
		padding-top: 87px;
		padding-bottom: 116px;
	}

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 37.8%;
		background: $white;
		pointer-events: none;

		@include tablet {
			display: none;
		}
	}

	&__lines {
		position: absolute;
		left: 0;
		top: 22%;
		width: 100%;
		padding: 0 16px;

		@include tablet {
			top: 14%;
		}
		@include phone {
			display: none;
		}

		svg {
			width: 100%;
			height: 100%;
		}
	}

	&__container {
		@extend %container;
		position: relative;
	}

	&__breadcrumbs {
		margin-top: 23px;

		@include phone {
			margin-top: 14px;
		}
	}

	&__subtitle {
		padding-top: 148px;
		font-weight: normal;
		font-size: 12px;
		line-height: 15px;
		text-align: center;
		letter-spacing: 11.5px;

		@include phone {
			display: none;
		}
	}

	&__title {
		padding-top: 17px;
		padding-bottom: 70px;
		font-weight: 500;
		font-size: 72px;
		line-height: 88px;
		text-align: center;
		text-transform: uppercase;

		@include tablet {
			font-size: 62px;
			line-height: 76px;
			padding-top: 15px;
			padding-bottom: 43px;
		}
		@media (max-width: 767px) {
			font-size: 28px;
			line-height: 34px;
		}
		@include phone {
			padding-top: 38px;
			padding-bottom: 52px;
		}
	}

	&__globe {
		width: 167px;
		height: 167px;
		margin: 0 auto;
		position: relative;
		@include flex-center();

		@include phone {
			width: 122px;
			height: 122px;
		}
	}

	&__globe-icon {
		box-shadow: 0px 10px 61px rgba(163, 184, 190, 0.25);
		border-radius: 100%;
		position: absolute;

		svg {
			width: 84px;
			height: 84px;

			@include phone {
				width: 62px;
				height: 62px;
			}
		}
	}

	&__globe-text {
		position: absolute;

		svg {
			animation: textCircleRotateAnimation 28s linear;
			width: 100%;
			height: 100%;
			color: rgba($color_body, 0.4);
		}
	}

	&__arrow-down {
		display: flex;
		justify-content: flex-end;
		padding-right: 200px;
		margin-top: -95px;

		@include tablet {
			padding-right: 14%;
			margin-top: -145px;
		}
		@include phone {
			padding-right: 6%;
			margin-top: -72px;

			svg {
				width: 80px;
				height: 149px;
			}
		}
	}

	&__body {
		display: flex;
		padding-top: 74px;

		@include tablet {
			padding-top: 85px;
		}

		@include phone {
			padding-top: 30px;
		}
	}
}
