.detail-tags {
	$that: &;

	display: flex;
	flex-wrap: wrap;
	align-items: baseline;
	margin-top: 56px;

	@include tablet {
		margin-top: 40px;
	}
	@include phone {
		margin-top: 30px;
	}

	&__name {
		flex: none;
		font-weight: normal;
		font-size: 16px;
		line-height: 26px;
	}

	&__body {
		flex: 1 1 0%;
		margin-left: 13px;
		overflow: hidden;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin-top: -16px;
		margin-left: -22px;
	}

	&__item {
		padding-top: 16px;
		padding-left: 22px;
	}

	&__link {
		display: inline-block;
		vertical-align: top;
		padding-bottom: 6px;
		color: rgba(34, 40, 45, 0.5);
		font-size: 12px;
		line-height: 15px;
		font-weight: 500;
		letter-spacing: 0.6px;
		text-transform: uppercase;
		border-bottom: 1px solid #e5e5e5;
		transition: color 0.15s ease, border-color 0.3s ease;

		&:hover {
			color: $color_primary;
			border-color: $color_primary;
		}

		&::before {
			content: '#';
		}

		@include phone {
			font-size: 10px;
			padding-bottom: 5px;
		}
	}

	.photo-detail__tags & {
		#{$that}__body {
			flex: 1 1 auto;
			display: flex;
			justify-content: center;
			margin-top: 12px;
			margin-left: 0px;
		}

		#{$that}__name {
			width: 100%;
			text-align: center;
		}
	}
}
