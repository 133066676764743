.investors-swiper {
	$that: &;
	position: relative;

	&__swiper-container.swiper-container {
		overflow: visible;
		margin-left: -23px;
		margin-right: -23px;
		margin-top: 62px;

		@include tablet {
			margin-left: -15px;
			margin-right: -15px;
		}

		@include phone {
			margin-top: 44px;
		}
	}

	&__swiper-wrapper.swiper-wrapper {
	}

	&__swiper-slide.swiper-slide {
		flex: 1 1 0%;
		max-width: 616px;
		padding-left: 23px;
		padding-right: 23px;
		//min-width: 560px;
		min-width: 288px;

		@include tablet {
			padding-left: 15px;
			padding-right: 15px;
		}

		@include phone {
			min-width: 288px;
		}
	}

	&__item {
		display: block;
	}

	&__picture {
		height: 525px;

		@include desktop-l {
			height: calc(525 / 1920 * 100vw);
		}

		@include tablet {
			height: 212px;
		}

		img {
			width: 100%;
			height: 100%;

			@include object-fit(cover, top right);
			@include lazy-loaded;
		}
	}

	&__body {
		@include flex-center();
		height: 323px;
		//background: $color_primary;
		position: relative;
		padding: 0 30px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		transition: background-color 0.25s ease-in-out;

		#{$that}__item:hover & {
			background: $color_primary;
		}

		@include tablet {
			height: 191px;
			padding: 0 16px;
		}
	}

	&__text {
		position: relative;
		z-index: 1;
		font-weight: 500;
		font-size: 24px;
		line-height: 1.75;
		text-align: center;
		text-transform: uppercase;
		text-decoration: underline;
		text-decoration-skip-ink: none;
		//color: $white;
		max-width: 471px;
		transition: color 0.25s ease-in-out;

		#{$that}__item:hover & {
			color: $white;
		}

		@include desktop-l {
			font-size: 20px;
		}

		@include laptop {
			font-size: 16px;
		}

		@include tablet {
			font-size: 14px;
		}
	}

	&__icon {
		position: absolute;
		color: rgba(209, 209, 209, 0.13);

		svg {
			width: 265px;
			height: 201px;

			@include tablet {
				width: 186px;
				height: 141px;
			}
		}
	}
}
