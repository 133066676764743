.detail-share {
	$that: &;

	overflow: hidden;

	&__columns {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	&__name {
		font-weight: normal;
		font-size: 16px;
		line-height: 26px;
	}

	&__social {
		padding-left: 21px;
	}

	&__list {
		display: flex;
	}

	&__item {
		border: 1px solid #e1e1e2;
		border-radius: 4px;
		width: 46px;
		height: 46px;
		@include flex-center();
		flex: none;
		margin-right: 14px;

		transition: background 0.5s cubic-bezier(0.1, 0, 0.14, 1),
			border-color 0.5s cubic-bezier(0.1, 0, 0.14, 1),
			color 0.5s cubic-bezier(0.1, 0, 0.14, 1);

		&:hover {
			@include laptop-hover {
				background: $color_primary;
				border-color: $color_primary;
				color: $white;
			}
		}

		&:last-child {
			margin-right: 0;
		}

		svg {
			width: 16px;
			height: 16px;
		}
	}
}
