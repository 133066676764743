.nav-footer {
	$that: &;

	padding: 0;
	list-style: none;

	&__item {
		color: rgba($color_body, 0.7);
		font-weight: normal;
		font-size: 14px;
		line-height: 30px;

		& + & {
			margin-top: 13px;
		}

		@include phone {
			text-align: center;
		}

		&._bold {
			margin-bottom: 16px;
			font-weight: 500;
			font-size: 12px;
			line-height: 15px;
			text-transform: uppercase;
			color: $color_body;
		}
	}

	&__link {
		display: inline-block;
		vertical-align: top;
		color: inherit;

		&:hover {
			@include laptop-hover {
				color: $color_primary;
			}
		}
	}
}
