.map-baloon {
	background: $white;
	border-radius: 4px;
	padding: 32px;
	max-width: 263px;
	width: 100%;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;

	svg {
		width: 22px;
		height: 22px;
		color: #7a7f84;
	}

	&__item {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		span,
		a {
			padding-left: 12px;
		}
	}

	&__name {
	}

	&__phone {
		margin: 18px 0;
	}

	&__email {
	}
}
