.about-principal {
	display: flex;
	position: relative;

	@include phone {
		display: block;
	}

	&__picture {
		width: 55%;
		height: 614px;

		@include laptop {
			width: 40%;
			height: 100%;
		}
		@include phone {
			width: 100%;
		}

		img {
			width: 100%;
			height: 100%;
			max-width: 469px;
			margin-left: auto;

			@include object-fit(cover, center);
			@include lazy-loaded;

			@include laptop {
				max-width: none;
				margin-left: 0;
			}
		}
	}

	&__main {
		font-weight: 500;
		font-size: 18px;
		line-height: 38px;
		padding-left: 92px;

		width: 45%;

		@include desktop {
			padding-left: 53px;
		}

		@include laptop {
			width: 60%;
		}

		@include tablet {
			font-size: 14px;
			line-height: 26px;
			padding-left: 24px;
		}
		@include phone {
			padding-left: 0;
			padding-top: 40px;
			width: 100%;
		}
	}

	&__position {
		position: absolute;
		left: calc(50% - 795px);
		top: 61px;

		@include desktop {
			top: -115px;
			left: 0;
		}
		@include laptop {
			position: static;
			padding-bottom: 32px;
		}
	}

	&__title {
		font-weight: 600;
		font-size: 24px;
		line-height: 29px;
		text-align: right;
		text-transform: uppercase;
		padding-bottom: 15px;

		@include laptop {
			text-align: left;
		}

		@include tablet {
			font-size: 16px;
			line-height: 20px;
			padding-bottom: 14px;
		}
	}

	&__name {
		font-weight: 500;
		font-size: 38px;
		line-height: 51px;
		text-align: right;
		text-transform: uppercase;
		color: $white;
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;
		max-width: 487px;

		@include laptop {
			text-align: left;
			justify-content: flex-start;
		}

		@include tablet {
			font-size: 24px;
			max-width: 420px;
		}

		span {
			background: $color_primary;
			margin-top: 5px;
			padding: 8px 12px 6px;

			&:nth-child(2) {
				padding-left: 0;

				@include phone {
					padding-left: 5px;
				}
			}

			@include tablet {
				padding: 0 11px;
			}
			@include phone {
				padding: 0 5px;
			}
		}
	}

	&__text {
		padding-bottom: 30px;
		max-width: 526px;

		@include laptop {
			max-width: none;
		}

		p + p {
			padding-top: 28px;

			@include laptop {
				padding-top: 24px;
			}
		}
	}

	&__link {
		font-weight: 500;
		font-size: 12px;
		line-height: 38px;
		text-transform: uppercase;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		span {
			border-bottom: 1px solid rgba(21, 22, 23, 0.1);
			margin-right: 22px;
		}
	}
}
