.content-slider {
	$that: &;

	margin: 56px 0;

	@include tablet {
		margin: 40px 0;
	}
	@include phone {
		margin: 32px 0;
	}

	&__picture {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(
				360deg,
				rgba(34, 40, 45, 0.3763) 0%,
				rgba(34, 40, 45, 0) 75.27%
			);
		}
	}

	&__image {
		border-radius: 4px 4px 0 0;
	}

	&__buttons {
		position: absolute;
		bottom: 16px;
		left: 50%;
		z-index: 1;
		transform: translate(-50%);
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		@include phone {
			bottom: 0px;
		}
	}

	&__prev {
		transform-origin: right center;
	}

	&__next {
		transform-origin: left center;
	}

	&__prev,
	&__next {
		display: flex;
		width: 48px;
		height: 48px;
		border: 0;
		padding: 0;
		background: transparent;
		cursor: pointer;
		transition: transform 0.25s ease;

		&:hover {
			transform: scale(1.333333333);
		}

		&.swiper-button-disabled {
			pointer-events: none;
		}
	}

	&__svg-prev,
	&__svg-next {
		color: $white;
		margin: auto;
	}

	&__caption {
		padding: 27px 0;
		font-weight: normal;
		font-size: 14px;
		line-height: 24px;
		border-bottom: 1px solid #e5e5e5;

		@include phone {
			font-size: 12px;
			padding: 16px 0;
		}
	}
}
