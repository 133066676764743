.breadcrumbs {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: #8b939a;
	clip: auto;
	text-align: left;

	&__list {
		display: inline-flex;
	}

	&__item {
		position: relative;
		flex: none;
		display: inline-flex;
		justify-content: flex-start;
		align-items: center;

		&:not(:first-child) {
			svg {
				margin-left: 7px;
			}

			@include phone {
				display: none;

				.--mobile-two & {
					&:nth-child(2) {
						display: inline-flex !important;
					}
				}
			}
		}

		span {
			color: #8b939a;
		}

		svg {
			width: 5px;
			height: 7px;
			margin-right: 7px;
			color: $black;
		}

		._b-light & {
			span {
				color: #8b939a;
			}
			svg {
				color: $white;
			}
		}
	}

	&__arrow-left {
		display: none;

		@include phone {
			display: block;
		}
	}

	&__arrow-right {
		margin-left: 7px;

		@include phone {
			display: none;
		}
	}

	&__link {
		font-weight: 500;
		font-size: 8px;
		line-height: 10px;
		color: $color_body;
		text-transform: uppercase;
		text-decoration: underline;
		text-decoration-skip-ink: none;

		@include phone {
			color: #8b939a;
		}

		._b-light & {
			color: $white;
		}
	}
}
