.portfolio {
	$that: &;
	padding-bottom: 185px;

	@include tablet {
		padding-bottom: 134px;
	}

	@include phone {
		padding-bottom: 84px;
	}

	&__inner {
		@extend %container;
	}

	&__body {
		padding-top: 62px;
		position: relative;

		@include phone {
			padding-top: 44px;
		}
	}

	&__swiper-container.swiper-container {
		overflow: visible;
	}

	&__swiper-wrapper.swiper-wrapper {
	}

	&__swiper-slide.swiper-slide {
		width: 893px;

		@include laptop {
			width: 715px;
		}

		@media (max-width: 767px) {
			width: 550px;
		}

		@include phone {
			width: 257px;
		}
	}

	&__item {
		text-align: center;

		&:hover {
			@include laptop-hover {
				#{$that}__picture {
					img {
						transform: scale(1.042);
					}
				}
				#{$that}__item-title {
					color: $white;

					span {
						&::before {
							transform: scaleY(1);
						}
					}
				}
			}
		}
	}

	&__picture {
		position: relative;

		img {
			width: 100%;
			height: 100%;

			@include object-fit(contain, center);
			@include lazy-loaded;

			transform: scale(1);
			transition: transform 2s cubic-bezier(0.25, 0.1, 0, 1);
			will-change: transform;
		}

		svg {
			width: 104px;
			height: 104px;
			position: absolute;
			left: -52px;
			bottom: -52px;
			box-shadow: 8px 38px 61px rgba(138, 148, 151, 0.08);
			border-radius: 100%;

			@include tablet {
				display: none;
			}
		}
	}

	&__item-title {
		font-weight: 600;
		font-size: 24px;
		line-height: 38px;
		text-transform: uppercase;
		padding-top: 60px;
		color: $color_body;
		transition: color 0.8s cubic-bezier(0.25, 0.1, 0, 1);

		@include tablet {
			padding-top: 48px;
		}
		@include phone {
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			padding-top: 19px;
			max-width: 230px;
		}

		span {
			position: relative;
			padding: 5px 12px;

			@include phone {
				padding: 0;
			}

			&::before {
				position: absolute;
				content: '';
				left: 0;
				top: 0;
				z-index: -1;
				width: 100%;
				height: 100%;
				background: $color_primary;
				transform: scaleY(0);
				transform-origin: bottom;
				transition: transform 1.2s cubic-bezier(0.25, 0.1, 0, 1);
				will-change: transform;
			}
		}
	}

	&__item-desc {
		font-weight: 600;
		font-size: 24px;
		line-height: 38px;
		color: $color_primary;

		@include phone {
			font-weight: 500;
			font-size: 12px;
			line-height: 20px;
			padding-top: 4px;
			max-width: 230px;
		}
	}

	&__arrow {
		padding-top: 47px;
		display: flex;
		justify-content: center;

		@include phone {
			padding-top: 26px;

			.arrow._big {
				width: 36px;
				height: 36px;

				svg {
					width: 14px;
					height: 11px;
				}
			}
		}
	}

	&__bottom {
		padding-top: 72px;
		max-width: 759px;
		margin: 0 auto;
		position: relative;

		@include phone {
			padding-top: 42px;
		}
	}

	&__bottom-lines {
		._line {
			position: absolute;
			bottom: -4px;
			width: 2px;
			height: 11px;
			background: $color_primary;
			border-radius: 4px;

			@include phone {
				width: 1px;
			}

			&:first-child {
				left: 0;
				margin-left: -4px;

				@include phone {
					margin: 0;
				}
			}
			&:last-child {
				right: 0;
				margin-right: -4px;

				@include phone {
					margin: 0;
				}
			}
		}
	}

	&__progressbar-icon {
		position: absolute;
		bottom: -8px;
		left: -10.5px;
		width: 100%;
		height: 21px;
		z-index: 20;

		@include phone {
			height: 14px;
			bottom: -6px;
			left: -7px;
		}

		._progressbar-icon {
			width: 21px;
			height: 21px;
			background: $white;
			border: 2px solid $color_primary;
			border-radius: 100%;

			@include phone {
				width: 14px;
				height: 14px;
				border-width: 1px;
			}
		}
	}

	&__swiper-pagination.swiper-pagination {
		position: static;
		max-width: 759px;
		margin: 0 auto;
		background: rgba(55, 59, 66, 0.22);
		height: 2px;

		@include phone {
			height: 1px;
		}

		.swiper-pagination-progressbar-fill {
			background: $color_primary;
		}
	}

	&__line {
		position: absolute;
		top: 35%;
		left: 0;
		width: 100%;
		height: 1px;
		background: $color_primary;
	}
}
