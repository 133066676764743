.green-invest {
	padding-bottom: 80px;
	background: $white;
	position: relative;
	z-index: 20;

	@include tablet {
		padding-bottom: 0px;
	}

	@include phone {
		padding-bottom: 87px;
	}

	&__container {
		@extend %container;
	}

	&__body {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		position: relative;

		@include tablet {
			display: block;
		}
	}

	&__link {
		display: block;
		background: $color_primary;
		padding: 89px 84px 71px 88px;
		color: $white;
		position: relative;
		z-index: 20;
		margin-top: -104px;
		min-width: 579px;
		max-width: 579px;

		@include laptop {
			padding-left: 40px;
			padding-right: 40px;
			max-width: 490px;
			min-width: 490px;
		}

		@include tablet {
			width: 100%;
			max-width: none;
			min-width: auto;
			padding: 133px 30px 73px;
			top: -40px;
			margin-top: 0;
			text-align: center;
		}

		@include phone {
			padding: 74px 30px 55px;
		}

		&:hover {
			color: $white;
		}
	}

	&__link-title {
		font-weight: 600;
		font-size: 24px;
		line-height: 29px;
		text-transform: uppercase;

		@include phone {
			line-height: 36px;
			max-width: 224px;
			margin: 0 auto;
		}
	}

	&__link-text {
		font-weight: 600;
		font-size: 18px;
		line-height: 31px;
		text-transform: uppercase;
		padding: 144px 0;

		@include tablet {
			padding: 57px 0 37px;
			max-width: 407px;
			margin: 0 auto;
		}

		@include phone {
			font-size: 14px;
			line-height: 28px;
			padding: 44px 0 55px;
			max-width: 217px;
		}
	}

	&__link-arrow {
		@include tablet {
			display: flex;
			justify-content: center;
		}
	}

	&__link-bg {
		display: flex;
		position: absolute;
		top: 210px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 0;
		pointer-events: none;

		@include tablet {
			top: 105px;
		}
		@include phone {
			top: 44px;
		}

		svg {
			width: 265px;
			height: 202px;
			color: rgba($white, 0.13);

			@include tablet {
				width: 134px;
				height: 102px;
			}
			@include phone {
				width: 192px;
				height: 146px;
			}
		}
	}

	&__desc {
		padding: 172px 0 0 202px;

		@include desktop {
			padding-left: 44px;
		}
		@include laptop {
			padding-top: 44px;
		}
		@include tablet {
			padding: 64px 0 0;
		}
		@include phone {
			padding: 44px 0 0;
		}
	}

	&__desc-text {
		font-weight: 500;
		font-size: 18px;
		line-height: 38px;
		max-width: 736px;
		padding-bottom: 30px;

		@include tablet {
			padding-bottom: 64px;
			margin: 0 auto;
			text-align: center;
		}
		@include phone {
			font-size: 16px;
			line-height: 34px;
			padding-bottom: 44px;
		}
	}

	&__globe {
		display: flex;
		margin-right: 160px;
		margin-left: auto;
		width: 85px;
		height: 85px;
		border-radius: 100%;
		box-shadow: 0px 10px 61px rgba(163, 184, 190, 0.25);

		@include tablet {
			margin: 0 auto;
		}

		svg {
			width: 100%;
			height: 100%;
		}
	}

	&__arrow-down {
		display: flex;
		justify-content: flex-end;
		padding-right: 100px;
		padding-top: 12px;

		@include tablet {
			margin-top: -24px;

			svg {
				transform-origin: top left;
				transform: rotateZ(-36deg);
			}
		}

		@include phone {
			display: none;
		}
	}
}
