.detail-content {
	$that: &;

	position: relative;
	background: $white;
	border-radius: 4px;
	max-width: 1204px;
	margin: 0 auto;
	box-sizing: content-box;

	&__head {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 28px 24px;
		border-bottom: 1px dashed rgba(0, 0, 0, 0.1);

		@include tablet {
			padding-top: 41px;
		}

		@include phone {
			padding-top: 32px;
			padding-left: 0;
			justify-content: space-between;
		}
	}

	&__tag {
		font-weight: 600;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.6px;
		text-transform: uppercase;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		flex: 1 1 auto;
		//padding-right: 48px;
		//transform: translateY(4px);
		margin: 0 -18px;

		span {
			padding-bottom: 8px;
			border-bottom: 1px solid rgba(34, 40, 45, 0.08);
			margin-right: 18px;
			margin-left: 18px;
			margin-top: 12px;

			/* 	&:last-of-type {
				margin-right: 0;
			} */

			#{$that}__tag:only-child & {
				margin-right: 0;
			}
		}

		@include phone {
			justify-content: flex-start;
		}
	}

	&__share {
		flex: none;
		//position: absolute;
		//right: 51px;
		margin-left: auto;
		padding-left: 18px;

		@include tablet {
			right: 38px;
		}
		@include phone {
			right: 0px;
		}
	}

	&__button-share {
		display: inline-block;
		vertical-align: middle;
		padding: 0;
		border: 0;
		background: transparent;
		cursor: pointer;
	}

	&__body {
		position: relative;
		padding: 55px 130px 101px;

		@include tablet {
			padding: 55px 66px 52px;
		}

		@include phone {
			padding: 22px 0px 36px;
		}
	}

	&__bottom-share {
		margin-top: 39px;
	}
}
