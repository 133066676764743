.contacts-page {
	$that: &;

	overflow: hidden;
	padding-top: 114px;
	padding-bottom: 131px;
	position: relative;
	background: #f9f9f9;

	@include tablet {
		padding-top: 110px;
		padding-bottom: 58px;
	}
	@include phone {
		padding-top: 87px;
		padding-bottom: 48px;
	}

	&__container {
		@extend %container;
		position: relative;
	}

	&__breadcrumbs {
		margin-top: 23px;

		@include phone {
			margin-top: 14px;
		}
	}

	&__body {
		position: relative;
		z-index: 20;
		max-width: 1558px;
		margin: 0 auto;

		@include laptop {
		}

		@include phone {
		}
	}

	&__block {
		@include tablet {
			margin-top: -64px;
		}
	}

	&__map {
		height: 763px;
		position: relative;
		border-radius: 4px;
		overflow: hidden;

		@include tablet {
			height: 513px;
		}

		@include phone {
			height: 276px;
			margin: 0 -30px;
		}
		@include phone-s {
			margin: 0 -16px;
		}

		img {
			&:nth-child(1) {
				width: 100%;
				height: 100%;

				@include object-fit(cover, top right);
			}
			&:nth-child(2) {
				position: absolute;
				top: 46%;
				left: 44%;
				z-index: 20;
			}
		}
		.map-baloon {
			position: absolute;
			top: 50%;
			left: 50%;

			@include phone {
				display: none;
			}
		}
	}

	&__request {
		display: none;
		background: $white;
		border-radius: 4px 4px 0 0;
		padding: 88px 88px 84px;
		border-bottom: 2px solid $color_primary;
		max-width: 511px;
		text-align: center;
		margin-top: -285px;
		margin-left: 50px;
		position: relative;
		z-index: 20;

		@include tablet {
			margin-top: -54px;
			margin-left: 30px;
			margin-right: 30px;
			max-width: none;
			padding: 88px 30px;
			border-bottom: none;
		}
		@include phone {
			margin-top: -26px;
			margin-left: 0;
			margin-right: 0;
			padding: 40px 30px 57px;
		}
	}

	&__request-title {
		font-weight: 500;
		font-size: 24px;
		line-height: 29px;
		text-align: center;
		text-transform: uppercase;
		padding-bottom: 10px;
	}

	&__form {
		@include tablet {
			max-width: 313px;
			margin: 0 auto;
		}
		@include phone {
			max-width: none;
		}
	}

	&__field {
		padding-top: 36px;
	}

	&__submit {
		padding-top: 60px;
	}

	.app-head {
		padding-top: 64px;

		@include phone {
			padding-top: 0px;
		}
	}
}
